import React, { useContext, useEffect, useState } from 'react';
import { message, Modal, Skeleton } from 'antd';
import LocationPolicyForm, {
  LocationPolicyFormProps,
  LocationPolicyFormValues,
} from './Form';
import {
  LocationPolicyFieldsFragment,
  CreateLocationPolicyInput,
  UpdateLocationPolicyInput,
  useV2Gateway_CreateLocationPoliciesMutation,
  useV2Gateway_UpdateLocationPoliciesMutation,
} from 'Generated/graphql';
import {
  LocationPolicyListItemProps,
} from './List/ListItem';
import { submitData } from 'Common/functions/FormChain/submit';
import {
  FormChainLinkSubmitFuncReturn,
} from 'Common/context/FormChainContextProvider';
import { Formik, FormikConfig, useFormikContext } from 'formik';
import {
  default as ManageLocationPoliciesContext,
} from 'Common/context/ManageLocationPoliciesContext';
import {
  createLocationPolicyFormValidationSchema,
  updateLocationPolicyFormValidationSchema,
} from './Form/validation';
import { deleteTypeNameField } from 'Common/functions/Form';
import { Button, Close, Flex } from 'theme-ui';
import LocationPolicyList from './List';
import {
  default as AppointmentsForLocationsContext,
} from 'Common/context/AppointmentsForLocationsContext';

export type ManageLocationPoliciesLocationPolicies =
  LocationPolicyFieldsFragment[]
export type ManageLocationPoliciesLocationPolicy =
  LocationPolicyFieldsFragment

type ManageLocationPoliciesProps = {
  listItemProps?: Partial<LocationPolicyListItemProps>;
  formProps?: Partial<Omit<LocationPolicyFormProps, 'onClickEdit'>>;
  disabled: boolean;
  externalUid: string;
  showModal?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const ManageLocationPolicies: React.FC<ManageLocationPoliciesProps> = ({
  listItemProps,
  formProps,
  disabled,
  showModal,
  onSubmit,
  onCancel,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    focusedLocationPolicy,
    dispatch,
    locationPolicies,
    isCreatingNew,
  } = useContext(ManageLocationPoliciesContext);
  const {
    submitForm,
    validateForm,
  } = useFormikContext();

  const content = locationPolicies ? (
    <>
      <Flex
        sx={{
          flexBasis: '100%',
        }}
      >
        <LocationPolicyList
          locationPolicies={locationPolicies}
          listItemProps={{
            ...listItemProps,
            disabled,
          }}
        />
      </Flex>
    </>
  ) : <Skeleton />;

  useEffect(() => {
    const isEditing = Boolean(focusedLocationPolicy) || isCreatingNew;
    setIsEditing(isEditing);
  }, [focusedLocationPolicy, isCreatingNew]);

  return (<>
    <Modal
      title="Location Policy"
      open={showModal}
      onCancel={() => {
        dispatch({
          focusedLocationPolicy: undefined,
          isCreatingNew: false,
        });
        onCancel && onCancel();
      }}
      onOk={async () => {
        const err = await validateForm();
        if (Object.keys(err).length > 0) {
          message.warning('Please ensure you filled out the form completely');
          console.warn(err);
          return;
        }
        submitForm().then(() => onSubmit && onSubmit());
      }}
      okButtonProps={{
        loading: disabled,
      }}
    >
      {content}
      {isEditing ? (
        <Flex
          sx={{
            alignItems: 'center',
            pt: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <LocationPolicyForm
            existingMod={focusedLocationPolicy || undefined}
            {...formProps}
          />
          <Button
            onClick={() => dispatch({
              focusedLocationPolicy: null,
              isCreatingNew: false,
            })}
            variant="buttons.secondary"
          >
            <Close />
          </Button>
        </Flex>
      ) : (
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button onClick={() => dispatch({ isCreatingNew: true })}>
            New Policy
          </Button>
        </Flex>
      )}
    </Modal>
  </>);
};

const ManageLocationPoliciesWithFormik: React.FC<
  Omit<ManageLocationPoliciesProps, 'submitForm' | 'disabled'>
> = props => {
  const {
    focusedLocationPolicy,
    dispatch,
    refetchLocationPolicies,
  } = useContext(ManageLocationPoliciesContext);
  const [
    updateLocationPolicies,
    updateLocationPoliciesQuery,
  ] = useV2Gateway_UpdateLocationPoliciesMutation();
  const [
    createLocationPolicies,
    createLocationPoliciesQuery,
  ] = useV2Gateway_CreateLocationPoliciesMutation();
  const {
    getAppointmentsForLocationsQuery,
  } = useContext(AppointmentsForLocationsContext);

  const create = async (data: CreateLocationPolicyInput[]): Promise<
    FormChainLinkSubmitFuncReturn
  > => {
    if (data.length) {
      const res = await createLocationPolicies({
        variables: {
          data,
        }
      });

      if (res.data?.createLocationPolicies) {
        return [[], true];
      }

      message.warning('Failed to create appointment request');

      return [null, false];
    }

    return [null, true];
  };

  const update = async (data: UpdateLocationPolicyInput[]): Promise<
    FormChainLinkSubmitFuncReturn<{
      internalUids: string[],
    }>
  > => {
    if (data.length) {
      const res = await updateLocationPolicies({
        variables: {
          data,
        }
      });

      if (res.data?.updateLocationPolicies) {
        return [[{ internalUids: data.map(d => d.uid) }], true];
      }

      message.warning('Failed to update appointment request');

      return [null, false];
    }

    return [null, true];
  };

  const onSuccess = () => {
    dispatch({
      focusedLocationPolicy: undefined,
      isCreatingNew: false,
    });
    refetchLocationPolicies();
    getAppointmentsForLocationsQuery &&
      getAppointmentsForLocationsQuery.refetch();
  };

  const submitForm = async (data: LocationPolicyFormValues[]) => {
    const res = await submitData<
      CreateLocationPolicyInput,
      UpdateLocationPolicyInput
    >(
      data,
      d => create(d),
      d => update(d as UpdateLocationPolicyInput[]),
    );

    return res;
  };

  const getInitialValue = () => {
    if (focusedLocationPolicy) {
      const m = deleteTypeNameField<
        ManageLocationPoliciesLocationPolicy
      >(focusedLocationPolicy);

      return {
        uid: m.uid,
        name: m.name,
        value: m.value,
      };
    }

    return {
      externalUid: props.externalUid,
      name: undefined,
      valueType: undefined,
      policyType: undefined,
      value: undefined,
    };
  };

  const formikCfg: FormikConfig<
    Partial<LocationPolicyFormValues>
  > = {
    initialValues: getInitialValue(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      values,
    ) => {
      submitForm([values as LocationPolicyFormValues]);
    },
    validationSchema: focusedLocationPolicy ?
      updateLocationPolicyFormValidationSchema :
      createLocationPolicyFormValidationSchema
  };

  useEffect(() => {
    const { data } = updateLocationPoliciesQuery;
    if (data?.updateLocationPolicies) {
      message.success('Location Policy Updated');
      onSuccess();
    }
  }, [updateLocationPoliciesQuery.data]);

  useEffect(() => {
    const { data } = createLocationPoliciesQuery;
    if (data?.createLocationPolicies) {
      message.success('Location Policy Created');
      onSuccess();
    }
  }, [createLocationPoliciesQuery.data]);

  return (
    <Formik
      {...formikCfg}
    >
      <ManageLocationPolicies
        {...props}
        disabled={updateLocationPoliciesQuery.loading ||
          createLocationPoliciesQuery.loading}
      />
    </Formik>
  );
};

export default ManageLocationPoliciesWithFormik;
