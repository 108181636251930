import { useState, useEffect, useRef } from 'react';
import {
  Field,
  Formik,
  FormikErrors,
  FormikProps,
  useFormikContext,
} from 'formik';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { InputNumber, message, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { Button, Flex, Input, Label, Spinner, Text } from 'theme-ui';
import {
  CreateFileInput,
  UpdateFileInput,
  V2Gateway_GetFilesQueryHookResult,
  V2Gateway_UploadFilesMutationHookResult,
  useV2Gateway_CreateFilesMutation,
  useV2Gateway_UpdateFilesMutation,
  useV2Gateway_UploadFilesMutation,
  File,
} from 'Generated/graphql';
import { getListSubmitFn } from 'Common/functions/Submit';
import {
  getFileFormCreateFn,
  getFileFormFormikConfig,
  getFileFormUpdateFn,
} from './fn';
import { ArrayValueObjectToArray } from 'Common/functions/FormChain/transform';

type input = CreateFileInput & UpdateFileInput
type inputSimple = Omit<input, 'uploadedFile'>;
type inputUploadedFile = input['uploadedFile'];

export type FileFormValues = {
  [K in keyof inputSimple]: inputSimple[K][];
} & {
  uploadedFile: {
    [K in keyof inputUploadedFile]: inputUploadedFile[K][];
  }
};
export type FilesFormValuesInput = CreateFileInput | UpdateFileInput

export type FileMutationReturn = {
  uid: string;
} | null

export type FileFormMethods = {
  submit: (externalUid: string) => Promise<FileMutationReturn[]>,
  validate: () => (Promise<FormikErrors<Partial<FileFormValues>>> | undefined),
  reset: () => void,
};

export enum FileFormFieldKey {
  ExternalUid = '0',
  Uid = '1',
  Index = '2',
  Label = '3',
  UploadedFile = '4',
  UploadedFile_PublicUrl = '5',
  UploadedFile_ObjectName = '6',
}

type Mods = NonNullable<
  V2Gateway_GetFilesQueryHookResult['data']
>['getFilesByExternalUid']

type UploadedFile = NonNullable<
  V2Gateway_UploadFilesMutationHookResult[1]['data']
>['uploadFiles'][number]

export type FileFormProps = {
  existingMods?: Mods;
  hideFields?: FileFormFieldKey[]
  titleHelp?: string;
  titlePlaceholder?: string;
  title?: string;
  onClickDelete?: (file: Mods[number]) => void;
  limit?: number;
  onMethods: (
    methods: FileFormMethods,
  ) => void;
  addButtonCopy?: string;
}

const ModFields: Record<
  FileFormFieldKey,
  keyof File | 'uploadedFile["publicUrl"]' | 'uploadedFile["objectName"]'
> = {
  [FileFormFieldKey.ExternalUid]: 'externalUid',
  [FileFormFieldKey.Uid]: 'uid',
  [FileFormFieldKey.Index]: 'index',
  [FileFormFieldKey.Label]: 'label',
  [FileFormFieldKey.UploadedFile]: 'uploadedFile',
  [FileFormFieldKey.UploadedFile_PublicUrl]: 'uploadedFile["publicUrl"]',
  [FileFormFieldKey.UploadedFile_ObjectName]: 'uploadedFile["objectName"]',
};

const FileForm: React.FC<FileFormProps> = ({
  existingMods,
  hideFields = [],
  titleHelp = 'A description of what is shown in the picture',
  titlePlaceholder = 'Lobby Interior',
  title = 'Images',
  onClickDelete,
  limit = 10,
  addButtonCopy = 'Add Image',
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadFiles, { data }] = useV2Gateway_UploadFilesMutation();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const {
    setFieldValue,
    getFieldMeta,
  } = useFormikContext<FileFormValues>();

  const uploadFile = async (file: RcFile): Promise<string> => {
    await uploadFiles({
      variables: {
        files: [file],
      },
    });
    return '';
  };

  const beforeUpload = (file: RcFile): boolean => {
    const isImage =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/webp';
    if (!isImage) {
      message.warning('You may only upload .jpg, .png, or .webp images.');
      return false;
    }
    const lessThanMaxSize = file.size / 1024 < 1024;
    if (!lessThanMaxSize) {
      message.warning('Upload failed. Image must no larger than 1mb.');
      return false;
    }
    uploadFile(file);
    return false;
  };

  const onUploadChange = (
    { file }: UploadChangeParam<UploadFile<any>>,
  ) => {
    if (file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (file.status === 'done') {
      setUploadLoading(false);
    }
  };

  useEffect(() => {
    if (data?.uploadFiles) {
      setUploadedFiles([...uploadedFiles, ...data.uploadFiles]);
    }
  }, [data]);

  const getFormItems = (
    existingMod?: Mods[number],
    uploadedFile?: UploadedFile,
    ix = 0,
  ) => {
    const allItems = Object.entries(ModFields).map(
      ([k, ff]) => {
        // hard-cast because Object.entries typing sucks
        const ffk = k as unknown as FileFormFieldKey;
        const fName = `${ff}[${ix}]`;
        const isHidden = hideFields.includes(ffk);

        switch (ffk) {
        case FileFormFieldKey.Index:
          return (
          // TODO: allow user to drag to reorder images/set index value
            <Field sx={{ display: 'none', }} as={InputNumber} name={ff} />
          );
        case FileFormFieldKey.Label:
          return (
            <Label
              key={ff}
              sx={isHidden ? {
                display: 'none',
              } : {}}
            >
Title
              <Field
                as={Input}
                name={fName}
                placeholder={titlePlaceholder}
                value={existingMod ? existingMod.label : undefined}
              />
              <Text>
                {titleHelp}
              </Text>
            </Label>
          );
        case FileFormFieldKey.UploadedFile_PublicUrl: {
          const pubUrl = existingMod ?
            existingMod.uploadedFile.publicUrl :
            uploadedFile ?
              uploadedFile.publicUrl :
              undefined;

          const meta = getFieldMeta(fName);
          if (!meta.value) {
            setFieldValue(fName, pubUrl);
          }

          return (
            <div
              key={ff}
              sx={{
                p: '1rem',
              }}
            >
              <Field
                as={Input}
                name={fName}
                type="hidden"
                value={uploadedFile ? uploadedFile.publicUrl : undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(fName, e.target?.value)}
              />
              {pubUrl ? (
                <img
                  src={pubUrl}
                  sx={{
                    maxWidth: '10rem',
                  }}
                />
              ) : null}
            </div>
          );
        }
        case FileFormFieldKey.UploadedFile_ObjectName: {
          const val = uploadedFile ? uploadedFile.objectName : undefined;
          const meta = getFieldMeta(fName);
          if (!meta.value) {
            setFieldValue(fName, val);
          }

          return (
            <div key={ff}>
              <Field
                as={Input}
                name={fName}
                type="hidden"
                value={val}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(fName, e.target?.value)}
              />
            </div>
          );
        }
        default:
          break;
        }
      });

    return (
      <Flex
        key={ix}
        sx={{
          mr: '1rem',
          flexDirection: 'column-reverse',
          alignItems: 'center',
        }}
      >
        {allItems}
      </Flex>
    );
  };

  return (
    <Flex
      sx={{
        overflow: 'auto',
        alignItems: 'center',
      }}
    >
      {existingMods &&
          existingMods.map((m, ix) => (
            <div key={ix} sx={{ position: 'relative' }}>
              <Button
                onClick={() => onClickDelete && onClickDelete(m)}
                variant="danger"
                sx={{
                  opacity: 0.3,
                  position: 'absolute',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <DeleteOutlined />
              </Button>
              {getFormItems(m, m.uploadedFile, ix)}
            </div>
          ))}
      {uploadedFiles.map((f, ix) => (
        <div key={ix} sx={{ position: 'relative' }}>
          <Button
            onClick={() => {
              const newList = uploadedFiles.filter(
                (el) => el.publicUrl !== f.publicUrl
              );
              setUploadedFiles(newList);
            }}
            variant="danger"
            sx={{
              opacity: 0.3,
              position: 'absolute',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <DeleteOutlined />
          </Button>
          {getFormItems(undefined, f, ix)}
        </div>
      ))}
      {(existingMods || []).length + uploadedFiles.length < limit && (
        <Upload
          accept=".jpg,.jpeg,.png,.webp"
          beforeUpload={beforeUpload}
          showUploadList={false}
          onChange={onUploadChange}
        >
          <Button>
            {uploadLoading ? (
              <Spinner size={32} />
            ) : (
              <Flex sx={{ alignItems: 'center' }}>
                <UploadOutlined />
                <span>{addButtonCopy}</span>
              </Flex>
            )}
          </Button>
        </Upload>
      )}
    </Flex>
  );
};

const FileFormWithFormik: React.FC<FileFormProps> = (props) => {
  const formikRef = useRef<
    FormikProps<Partial<FileFormValues>>
  >(null); // This is a ref that will be attached to Formik

  const [
    createFiles,
  ] = useV2Gateway_CreateFilesMutation();
  const [
    updateFiles,
  ] = useV2Gateway_UpdateFilesMutation();

  type cf = ((d: CreateFileInput) => Promise<FileMutationReturn>)
  type uf = ((d: UpdateFileInput) => Promise<FileMutationReturn>)

  const submit = getListSubmitFn<
  FileMutationReturn,
  CreateFileInput[],
  UpdateFileInput[],
  cf,
  uf
  >(
    getFileFormCreateFn(createFiles),
    getFileFormUpdateFn(updateFiles),
  );

  const submitForm = async (externalUid: string) => {
    if (formikRef.current) {
      const fd = formikRef.current.values as FileFormValues;
      const allData = ArrayValueObjectToArray(fd) as FilesFormValuesInput[];
      const data = allData.map(d => ({
        ...d,
        externalUid,
      }));

      const createData = data.filter(
        d => (d as CreateFileInput).uploadedFile,
      ) as CreateFileInput[];
      const updateData = data.filter(
        d => !(d as CreateFileInput).uploadedFile,
      ) as UpdateFileInput[];

      const ps = submit(createData, updateData);
      return ps;
    }
    return Promise.reject('empty formikRef');
  };

  const validateForm = () => {
    return formikRef.current?.validateForm();
  };

  const resetForm = () => {
    formikRef.current?.resetForm();
  };

  useEffect(() => {
    props.onMethods({
      submit: submitForm,
      validate: validateForm,
      reset: resetForm,
    });
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      {...getFileFormFormikConfig(
        props.existingMods || null,
      )}
    >
      <FileForm {...props} />
    </Formik>
  );
};

export default FileFormWithFormik;

