import { message, Modal } from 'antd';
import {
  UseLocationSubscriptionRequests,
} from 'Common/hooks/useLocationSubscriptionRequests';
import { V2Gateway_QueryLocationsQueryHookResult } from 'Generated/graphql';
import React from 'react';
import { Box, Button, Flex, Heading, Text } from 'theme-ui';
import LocationSelect from '../LocationSelect';
import SubscribeToLocationButton from '../SubscribeToLocationButton';
import ThemePeazy from 'Common/themePeazy.json';

type locations = NonNullable<
  V2Gateway_QueryLocationsQueryHookResult['data']
>['queryLocations']['locations']

type PromptJoinLocationProps = {
  isOpen: boolean;
  locations: locations;
  onChange?: () => void;
  onClose: () => void;
  existingLocationSubscriptionLocationUids: string[];
  locationSubscriptionRequests: UseLocationSubscriptionRequests;
}

const PromptJoinLocation: React.FC<PromptJoinLocationProps> = ({
  isOpen,
  locations,
  onChange,
  onClose,
  existingLocationSubscriptionLocationUids,
  locationSubscriptionRequests,
}) => {

  return (
    (<Modal
      open={isOpen}
      title=""
      footer={<></>}
      onCancel={() => onClose()}
    >
      <>
        <Heading
          variant='h1'
          sx={{
            fontWeight: 'bold',
            mb: '1rem',
          }}
        >
          Personal Services Where You Are. It&apos;s Peazy.
        </Heading>
        <Text
          variant="body2"
          as="p"
          sx={{
            mb: '1.5rem',
          }}
        >
          Join To Be Notified Of Services Coming To Your Building:
        </Text>
        <Box
          sx={{
            m: '1rem 0',
          }}
        >
          <LocationSelect
            locations={locations}
            locationListItemProps={{
              extra: (l, locPolicies) => {
                return (
                  <SubscribeToLocationButton
                    existingLocationSubscriptionLocationUids={existingLocationSubscriptionLocationUids}
                    locationUid={l.uid}
                    locationPolicies={locPolicies}
                    locationSubscriptionRequests={locationSubscriptionRequests}
                    onCreateLocationSubscription={() => {
                      message.success('Subscribed!');
                      onChange && onChange();
                    }}
                    onCreateLocationSubscriptionRequest={() => {
                      message.info('Subscription Requested');
                      onChange && onChange();
                    }}
                    onDeleteLocationSubscription={() => {
                      message.info('Subscription Removed');
                      onChange && onChange();
                    }}
                    onDeleteLocationSubscriptionRequest={() => {
                      message.info('Request Cancelled');
                      onChange && onChange();
                    }}
                  />
                );
              }
            }}
          />
        </Box>
        <Flex sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
          <Button
            data-testid="promptJoinLocationDone"
            variant='widePrimaryButton'
            onClick={() => onClose()}
            sx={{
              mb: '0.5rem',
            }}
          >
            Done
          </Button>
          <Button
            variant='link'
            onClick={() => onClose()}
            sx={{
              color: ThemePeazy.colors.darkBlue,
              p: 0
            }}
          >
            No Thanks
          </Button>
        </Flex>
      </>
    </Modal>)
  );
};

export default PromptJoinLocation;
