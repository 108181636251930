import React, { useContext, useEffect } from 'react';
import {
  CreateLocationInput,
  LocationType,
  UpdateLocationInput,
  useV2Gateway_CreateLocationsMutation,
  useV2Gateway_UpdateLocationsMutation,
  V2Gateway_GetLocationsQueryHookResult as GetLocationsQueryHookResult,
} from 'Generated/graphql';
import {
  getFormItems,
  getLocationFormFormikConfig,
  getLocationFormSubmitFn,
  LocationModFieldKey,
  getLocationFormCreateFn,
  getLocationFormUpdateFn,
} from './functions';
import { Formik, useFormikContext } from 'formik';
import getFormChainContext from 'Common/context/FormChainContext';
import { LocationFormChainLinkLabel } from '../ManageLocations/LocationList';
import {
  FormChainLinkSubmitFuncReturn,
} from 'Common/context/FormChainContextProvider';
import { Box, Button, Flex, Spinner } from 'theme-ui';
import ManageLocationsContext from 'Common/context/ManageLocationsContext';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { message } from 'antd';

export type LocationFormValues = CreateLocationInput | UpdateLocationInput

export type CreateLocationMutationReturn = {
  internalUids: string[];
}

type existingLocation = Omit<NonNullable<
    GetLocationsQueryHookResult['data']
  >['getLocations'][number], 'serviceAreas'>

type LocationFormProps = {
  existingLocation?: existingLocation;
  hideFields?: LocationModFieldKey[];
  submitFn: (v: LocationFormValues) => Promise<
    FormChainLinkSubmitFuncReturn<CreateLocationMutationReturn>
  >;
  overrideFields?: Partial<LocationFormValues>;
  hideTypeOptions?: LocationType[];
  isLoading: boolean;
  children?: React.ReactNode;
  isDisabled?: boolean;
  submitButtonCopy?: string;
}

const LocationForm: React.FC<LocationFormProps> = ({
  hideFields: pHideFields = [],
  existingLocation,
  submitFn,
  overrideFields,
  hideTypeOptions,
  isLoading,
  children,
  isDisabled,
  submitButtonCopy,
}) => {
  const {
    setFormLink,
    links,
    submitForms,
  } = useContext(getFormChainContext<
    Record<string, never>,
    CreateLocationMutationReturn
  >());
  const {
    values,
    resetForm,
    validateForm,
    setFieldValue,
    getFieldProps,
  } = useFormikContext<LocationFormValues>();
  const {
    dispatch,
  } = useContext(ManageLocationsContext);
  const formValueType = getFieldProps('type').value;
  const hideFields = formValueType === LocationType.Partner ?
    [...pHideFields, LocationModFieldKey.ExtendedAddress] :
    pHideFields;

  const onSuccess = () => {
    dispatch({
      newLocations: [],
    });
  };

  const doSubmit = () => {
    if (isLoading) {
      return;
    }

    validateForm().then(errors => {
      if (Object.keys(errors).length > 0) {
        console.warn(errors);
      } else {
        if (links) {
          submitForms().then(success => {
            if (success) {
              resetForm();
              message.success('Success!');
              onSuccess();
            }
          });
        } else {
          submitFn(values);
        }
      }
    });
  };

  useEffect(() => {
    if (setFormLink) {
      setFormLink({
        submitFunc: () => {
          const s = submitFn(values);
          return s;
        },
        label: LocationFormChainLinkLabel,
      });
    }
  }, [values]);

  useEffect(() => {
    if (overrideFields) {
      Object.entries(overrideFields).forEach(([k, v]) => {
        setFieldValue(k, v);
      });
    }
  }, [overrideFields]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        gap: '1rem'
      }}
    >
      {getFormItems(
        hideFields,
        existingLocation,
        hideTypeOptions,
      )}
      <Box>
        {children}
      </Box>
      <Flex sx={{
        justifyContent: 'center'
      }}>
        <Button
          data-testid="locationFormSubmit"
          variant='widePrimaryButton'
          onClick={() => isDisabled ? void 0 : doSubmit()}
          disabled={isLoading || isDisabled}
          sx={{
            mb: '1rem',
            opacity: isDisabled ? 0.4 : 1,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
        >
          {isLoading ? <Spinner size={24} /> : (submitButtonCopy ||
            'Save')}
        </Button>
      </Flex>
    </Flex>
  );
};

type location = NonNullable<
    GetLocationsQueryHookResult['data']
  >['getLocations'][number]

type LocationFormWithFormikProps = {
  existingLocation?: Omit<location, 'serviceAreas'>;
  hideFields?: LocationModFieldKey[];
  onCreateLocations?: (uids: string[]) => void;
  onUpdate?: () => void;
  overrideFields?: Partial<LocationFormValues>;
  hideTypeOptions?: LocationType[];
  children?: React.ReactNode;
  isDisabled?: boolean;
  submitButtonCopy?: string;
}

const LocationFormWithFormik: React.FC<LocationFormWithFormikProps> = ({
  existingLocation,
  hideFields = [],
  onCreateLocations,
  onUpdate,
  overrideFields,
  hideTypeOptions,
  children,
  isDisabled,
  submitButtonCopy,
}) => {
  const [
    createLocations,
    createLocationsQuery,
  ] = useV2Gateway_CreateLocationsMutation();
  const [
    updateLocations,
    updateLocationsQuery,
  ] = useV2Gateway_UpdateLocationsMutation();
  const {
    links,
  } = useContext(getFormChainContext<
    Record<string, never>,
    CreateLocationMutationReturn
  >());

  const submit = getLocationFormSubmitFn(
    getLocationFormCreateFn(createLocations),
    getLocationFormUpdateFn(updateLocations),
  );

  useEffect(() => {
    const { data } = createLocationsQuery;
    if (data?.createLocations) {
      if (!links) {
        message.success('Location saved');
      }
      const newLocUids = data.createLocations.internalUids;
      onCreateLocations && onCreateLocations(newLocUids);
      logEvent(
        getAnalytics(),
        'location_created',
      );
    }
  }, [createLocationsQuery.data]);

  useEffect(() => {
    const { data } = updateLocationsQuery;
    if (data?.updateLocations) {
      onUpdate && onUpdate();
      logEvent(
        getAnalytics(),
        'location_updated',
      );
    }
  }, [updateLocationsQuery.data]);

  return (
    <>
      <Formik
        {...getLocationFormFormikConfig(
          existingLocation || null,
          submit,
        )}
      >
        <LocationForm
          isDisabled={isDisabled}
          submitFn={v => submit([v])}
          hideFields={hideFields}
          existingLocation={existingLocation}
          overrideFields={overrideFields}
          hideTypeOptions={hideTypeOptions}
          isLoading={createLocationsQuery.loading ||
            updateLocationsQuery.loading}
          submitButtonCopy={submitButtonCopy}
        >
          {children}
        </LocationForm>
      </Formik>
    </>
  );
};

export default LocationFormWithFormik;
