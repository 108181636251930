import { ThemeUIStyleObject } from 'theme-ui';

const styles: Record<string, ThemeUIStyleObject> = {
  locationHeader: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1rem 0'
  },
  locationHeaderButtons: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '0.5rem'
  },
};

export default styles;

