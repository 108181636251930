import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'theme-ui';

export const getLocationPolicyListItemEditButton = (
  onClick: () => void,
) => (
  disabled: boolean,
): React.ReactNode => (
  <Button
    variant="buttons.secondary"
    onClick={() => onClick()}
    disabled={disabled}
  >
    <EditOutlined />
  </Button>
);
