import { LocationFormValues } from 'Common/components/LocationForm';
import {
  createLocationFormValidationSchema,
  updateLocationFormValidationSchema,
  getLocationFormInitialValue,
} from 'Common/components/LocationForm/functions';
import {
  ManageLocationsLocation
} from 'Common/components/ManageLocations/LocationListItem/ManageLocation';
import {
  CreateLocationInput,
  Maybe,
  UpdateLocationInput,
  V2Gateway_CreateLocationsMutationHookResult,
  V2Gateway_UpdateLocationsMutationHookResult,
} from 'Generated/graphql';
import { FormikConfig } from 'formik';

export const getLocationFormCreateFn = (
  doCreate: V2Gateway_CreateLocationsMutationHookResult[0],
) => async (data: CreateLocationInput): Promise<{
      uid: string,
    } | null> => {
  const res = await doCreate({
    variables: {
      data,
    }
  });

  if (res.data?.createLocations) {
    return {
      uid: res.data.createLocations.internalUids[0],
    };
  }

  return null;
};

export const getLocationFormUpdateFn = (
  doUpdate: V2Gateway_UpdateLocationsMutationHookResult[0],
) => async (data: UpdateLocationInput): Promise<{
      uid: string,
    } | null> => {
  const res = await doUpdate({
    variables: {
      data,
    }
  });

  if (res.data?.updateLocations) {
    return {
      uid: data.uid
    };
  }

  return null;
};

export const getLocationFormFormikConfig = (
  focusedMod: Maybe<ManageLocationsLocation>,
  submitForm: (values: LocationFormValues) => Promise<unknown>,
) => {
  const initialValues =
    getLocationFormInitialValue(focusedMod);
  const formikCfg: FormikConfig<
    Partial<LocationFormValues>
  > = {
    initialValues,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      values,
      { resetForm },
    ) => {
      submitForm(values as LocationFormValues).then(() => {
        resetForm();
      });
    },
    validationSchema: focusedMod ?
      updateLocationFormValidationSchema :
      createLocationFormValidationSchema,
  };

  return formikCfg;
};
