import React from 'react';
import { Flex, Text } from 'theme-ui';
import {
  FileFormFieldKey,
  FileFormMethods,
} from 'Common/components/ManageFiles/Form';
import { Files } from 'Common/context/FilesContextProvider';
import ManageFiles from 'Common/components/ManageFiles';
import { ManageLocationsLocation } from '.';

export const getFilesForm = (
  filesForLocation: Files,
  onMethods: (
    methods: FileFormMethods,
  ) => void,
  index?: number,
): React.ReactNode => {
  return (
    <Flex>
      <ManageFiles
        onMethods={onMethods}
        files={filesForLocation}
        inline
        formProps={{
          title: 'Logo',
          limit: 2,
          hideFields: [FileFormFieldKey.Label],
          addButtonCopy: 'Add Logo',
        }}
        //index={index}
      />
    </Flex>
  );
};

export const filterFilesForLocations = (
  location: Pick<ManageLocationsLocation, 'uid'>,
  allFiles: Files,
): Files => allFiles.filter(
  f => f.externalUid === location.uid
);

export const getFilesPresentation = (
  filesForLocation?: Files,
): React.ReactNode => {
  if (filesForLocation) {
    return filesForLocation.map((m, ix) => (
      <Flex
        key={ix}
        sx={{
          flexDirection: 'column',
        }}
      >
        <picture>
          <img
            src={m.uploadedFile.publicUrl}
            sx={{
              maxWidth: '10rem',
            }}
          />
        </picture>
        <Text as="em">
          {m.label}
        </Text>
      </Flex>
    ));
  }
};
