import React from 'react';
import {
  FormChainContext,
} from './FormChainContextProvider';

const ctx = React.createContext({});

const getContext = <
  D extends Record<string, unknown> = Record<string, unknown>,
  R extends Record<string, unknown> = Record<string, unknown>
  // hard cast because of type args
>() => ctx as unknown as React.Context<FormChainContext<D, R>>;

export default getContext;

