import {
  UseLocationSubscriptionRequests,
} from 'Common/hooks/useLocationSubscriptionRequests';
import {
  LocationPolicyType,
  useV2Gateway_CreateLocationSubscriptionRequestsMutation,
  useV2Gateway_CreateLocationSubscriptionsMutation,
  useV2Gateway_DeleteLocationSubscriptionMutation,
  useV2Gateway_DeleteLocationSubscriptionRequestMutation,
} from 'Generated/graphql';
import { UseLocationPolicies } from 'Common/hooks/useLocationPolicies';
import { Button } from 'theme-ui';
import { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Theme from 'Common/themePeazy.json';

type SubscribeToLocationButtonProps = {
  locationSubscriptionRequests: UseLocationSubscriptionRequests;
  existingLocationSubscriptionLocationUids?: string[];
  locationPolicies?: UseLocationPolicies;
  locationUid: string;
  onCreateLocationSubscription?: () => void;
  onCreateLocationSubscriptionRequest?: () => void;
  onDeleteLocationSubscription?: () => void;
  onDeleteLocationSubscriptionRequest?: () => void;
}

const SubscribeToLocationButton: React.FC<SubscribeToLocationButtonProps> = ({
  locationSubscriptionRequests,
  existingLocationSubscriptionLocationUids: existingLocationSubscriptionUids,
  locationPolicies,
  locationUid,
  onCreateLocationSubscription,
  onCreateLocationSubscriptionRequest,
  onDeleteLocationSubscription,
  onDeleteLocationSubscriptionRequest,
}) => {
  const [
    createLocationSubscriptions,
    createLocationSubscriptionsQuery,
  ] = useV2Gateway_CreateLocationSubscriptionsMutation();
  const [
    createLocationSubscriptionRequests,
    createLocationSubscriptionRequestsQuery,
  ] = useV2Gateway_CreateLocationSubscriptionRequestsMutation();
  const [
    deleteLocationSubscription,
    deleteLocationSubscriptionQuery,
  ] = useV2Gateway_DeleteLocationSubscriptionMutation();
  const [
    deleteLocationSubscriptionRequest,
    deleteLocationSubscriptionRequestQuery,
  ] = useV2Gateway_DeleteLocationSubscriptionRequestMutation();
  const lsr = locationSubscriptionRequests?.find(
    r => r.locationSubscription.locationUid === locationUid
  );
  const getAlreadySubbed = () => {
    if (existingLocationSubscriptionUids) {
      const isExistingSub =
        existingLocationSubscriptionUids.includes(locationUid);
      if (isExistingSub) {
        return isExistingSub;
      }
    }

    if (locationSubscriptionRequests) {
      return Boolean(lsr);
    }

    return false;
  };
  const alreadySubbed = getAlreadySubbed();
  const privateSubPol = locationPolicies?.find(
    lp => lp.policyType === LocationPolicyType.SubscriptionAutomaticApproval
      && lp.externalUid === locationUid
  );
  const isPrivate = privateSubPol?.value === 'false';
  const loading = createLocationSubscriptionRequestsQuery.loading ||
    createLocationSubscriptionsQuery.loading ||
    deleteLocationSubscriptionQuery.loading ||
    deleteLocationSubscriptionRequestQuery.loading;

  useEffect(() => {
    const { data } = createLocationSubscriptionRequestsQuery;
    if (data?.createLocationSubscriptionRequests) {
      onCreateLocationSubscriptionRequest &&
        onCreateLocationSubscriptionRequest();
    }
  }, [createLocationSubscriptionRequestsQuery.data]);

  useEffect(() => {
    const { data } = createLocationSubscriptionsQuery;
    if (data?.createLocationSubscriptions) {
      onCreateLocationSubscription && onCreateLocationSubscription();
    }
  }, [createLocationSubscriptionsQuery.data]);

  useEffect(() => {
    const { data } = deleteLocationSubscriptionQuery;
    if (data?.deleteLocationSubscription) {
      onDeleteLocationSubscription && onDeleteLocationSubscription();
    }
  }, [deleteLocationSubscriptionQuery.data]);

  useEffect(() => {
    const { data } = deleteLocationSubscriptionRequestQuery;
    if (data?.deleteLocationSubscriptionRequest) {
      onDeleteLocationSubscriptionRequest &&
        onDeleteLocationSubscriptionRequest();
    }
  }, [deleteLocationSubscriptionRequestQuery.data]);

  return (alreadySubbed || lsr) ? (
    <Button
      variant="timePill"
      disabled={loading}
      sx={{
        background: Theme.colors.darkBlue,
        color: 'white',
        maxWidth: '64px',
        fontSize: '2rem',
        position: 'relative',
        '&::before': {
          content: '\'✓\'',
        },
        '&:hover': {
          fontSize: '1.5rem',
          opacity: 0.9,
        },
        '&:hover::before': {
          content: '\'X\'',
        }
      }}
      onClick={() => {
        if (isPrivate && lsr) {
          deleteLocationSubscriptionRequest({
            variables: {
              uid: lsr.uid,
            }
          });
        } else {
          deleteLocationSubscription({
            variables: {
              locationUid,
            }
          });
        }
      }}
    >
    </Button>
  ) : (
    <>
      <Button
        variant="timePill"
        sx={{
          py: ['0.1rem', '0.2rem'],
          fontSize: '0.8rem',
          color: Theme.colors.darkBlue,
          width: '4rem',
          '&:hover': {
            opacity: 0.8,
          },
        }}
        disabled={loading}
        onClick={() => {
          const locationSubscription = {
            locationUid,
          };
          if (isPrivate) {
            createLocationSubscriptionRequests({
              variables: {
                data: [{
                  locationSubscription,
                }],
              },
            });
          } else {
            createLocationSubscriptions({
              variables: {
                data: [locationSubscription]
              }
            }).then(() => logEvent(
              getAnalytics(),
              'location_subscription_created',
            ));
          }
        }}
      >
        Join
      </Button>
    </>
  );
};

export default SubscribeToLocationButton;

