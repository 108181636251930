import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'theme-ui';
import {
  LocationType,
} from 'Generated/graphql';
import StripeConnectContext from 'Common/context/StripeConnectContext';
import StripeConnectModal from 'Common/components/StripeConnectModal';
import { message } from 'antd';
import { DashboardKeys } from '@/types';

type LocationTypeSelectProps = {
  onSelect?: (t: LocationType) => void;
  initialValue?: string | undefined;
  value: LocationType;
  hideOptions?: LocationType[];
}

const LocationTypePretty: Record<LocationType, string> = {
  [LocationType.Partner]: 'Condo/Apartment',
  [LocationType.Business]: 'Corporate Office',
  [LocationType.ServiceProvider]: 'Service Provider',
  [LocationType.Individual]: 'Residential',
  [LocationType.IndividualWork]: 'Work',
  [LocationType.IndividualIndeterminate]: 'Residential',
};

const LocationTypeSelect: React.FC<LocationTypeSelectProps> = ({
  value,
  onSelect,
  initialValue,
  hideOptions,
}) => {
  const { hasConnectedStripeAccount } = useContext(StripeConnectContext);
  const [showStripeConnectModal, setShowStripeConnectModal] = useState(false);

  const handleSelect = (t: string) => {
    onSelect && onSelect(t as LocationType);
    if (t === LocationType.Partner) {
      if (hasConnectedStripeAccount === false) {
        setShowStripeConnectModal(true);
      }
    }
  };

  const onStripeModalClose = () => {
    setShowStripeConnectModal(false);
    message.warning(
      'You won\'t recieve commission until you link a Stripe account.'
    );
  };

  useEffect(() => {
    if (hasConnectedStripeAccount === false) {
      const t = value;
      if (t === LocationType.Partner) {
        setShowStripeConnectModal(true);
      }
    }
  }, [hasConnectedStripeAccount]);

  return (
    <>
      <Select
        value={initialValue || value}
        sx={{
          pr: '1.5rem',
        }}
        onChange={e => {
          const t = e.currentTarget.value;
          handleSelect(t);
        }}
      >
        {Boolean(value) || <option value=""></option>}
        {Object.entries(LocationType).map(
          ([, v]) => hideOptions?.includes(v) || (
            <option value={v} key={v}>
              {LocationTypePretty[v]}
            </option>
          )
        )}
      </Select>
      {showStripeConnectModal && (
        <StripeConnectModal
          visible={true}
          returnToView={DashboardKeys.LOCATIONS}
          onCancel={() => onStripeModalClose()}
        />
      )}
    </>
  );
};

export default LocationTypeSelect;
