import React, { useEffect, useReducer, useRef } from 'react';
import {
  default as ManageLocationPoliciesContextComponent,
} from 'Common/context/ManageLocationPoliciesContext';
import { dispatch } from '../Dispatch';
import {
  ManageLocationPoliciesLocationPolicy,
  ManageLocationPoliciesLocationPolicies,
} from 'Common/components/ManageLocationPolicies';
import {
  useV2Gateway_QueryLocationPoliciesLazyQuery,
  V1Beta3_ReferenceMatch,
} from 'Generated/graphql';
import isDeepEqual from 'fast-deep-equal/react';

export type ManageLocationPoliciesContext = {
  focusedLocationPolicy: ManageLocationPoliciesLocationPolicy | null;
  dispatch: ManageLocationPoliciesContextDispatch;
  refetchLocationPolicies: () => void;
  locationPolicies: ManageLocationPoliciesLocationPolicies | null;
  isCreatingNew: boolean;
}

export type ManageLocationPoliciesContextDispatch = dispatch<
  ManageLocationPoliciesContext
>

type ManageLocationPoliciesContextProviderProps = {
  references: V1Beta3_ReferenceMatch[];
  children?: React.ReactNode;
}

const initialState: Omit<
  ManageLocationPoliciesContext,
  'dispatch' | 'refetchLocationPolicies'
> = {
  focusedLocationPolicy: null,
  locationPolicies: null,
  isCreatingNew: false,
};

const getManageLocationPoliciesContextProvider = (
  state: ManageLocationPoliciesContext,
) => (
  refetchLocationPolicies: () => void,
) => (
  dispatch: ManageLocationPoliciesContextDispatch
) => (
  children: React.ReactNode,
) => (
  <ManageLocationPoliciesContextComponent.Provider value={{
    ...state,
    dispatch,
    refetchLocationPolicies,
  }}>
    {children}
  </ManageLocationPoliciesContextComponent.Provider>
);

const ManageLocationPoliciesContextProvider: React.FC<
  ManageLocationPoliciesContextProviderProps
> = ({
  children,
  references,
}) => {
  const reducer: React.Reducer<
      ManageLocationPoliciesContext,
      Partial<ManageLocationPoliciesContext>
    > = (state, action): ManageLocationPoliciesContext => {
      state = { ...state, ...action };
      return state;
    };
  const [state, dispatch] = useReducer(
    reducer,
      initialState as ManageLocationPoliciesContext,
  );
  const [
    queryLocationPolicies,
    queryLocationPoliciesQuery,
  ] =
      useV2Gateway_QueryLocationPoliciesLazyQuery({
        fetchPolicy: 'network-only',
      });
  const pReferences = useRef(references);
  if (!isDeepEqual(pReferences.current, references)) {
    pReferences.current = references;
  }

  const refetchLocationPolicies = () => {
    if (queryLocationPoliciesQuery.refetch) {
      queryLocationPoliciesQuery.refetch();
    }
  };

  const renderContextProvider =
      getManageLocationPoliciesContextProvider(
        state
      )(
        refetchLocationPolicies
      )(
        dispatch
      );

  const setLocationPolicies = (
    locationPolicies: ManageLocationPoliciesLocationPolicies,
  ) => {
    dispatch({
      locationPolicies,
    });
  };

  useEffect(() => {
    const { data } = queryLocationPoliciesQuery;
    if (data?.queryLocationPolicies) {
      setLocationPolicies(data.queryLocationPolicies);
    }
  }, [queryLocationPoliciesQuery.data]);

  useEffect(() => {
    queryLocationPolicies({
      variables: {
        query: {
          references,
          pagination: {
            page: 1,
            pageSize: 50,
          },
        }
      }
    });
  }, [pReferences.current]);

  return renderContextProvider(children);
};

export default ManageLocationPoliciesContextProvider;

