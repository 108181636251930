import {
  CreateServiceAreaInput,
  Maybe,
  UpdateServiceAreaInput,
  V2Gateway_CreateServiceAreasMutationHookResult,
  V2Gateway_GetServiceAreasLazyQueryHookResult,
  V2Gateway_UpdateServiceAreasMutationHookResult,
} from 'Generated/graphql';
import { FormikConfig } from 'formik';
import { ServiceAreaFormValues } from '.';
import { deleteTypeNameField } from 'Common/functions/Form';
import * as yup from 'yup';

type ServiceAreas = NonNullable<
  V2Gateway_GetServiceAreasLazyQueryHookResult[1]['data']
>['getServiceAreas']
type ServiceArea = ServiceAreas[number]

export const getServiceAreaFormCreateFn = (
  doCreate: V2Gateway_CreateServiceAreasMutationHookResult[0],
) => async (data: CreateServiceAreaInput): Promise<{
      uid: string,
    } | null> => {
  const res = await doCreate({
    variables: {
      data,
    }
  });

  const rUid = res.data?.createServiceAreas[0].uid;
  if (rUid) {
    return {
      uid: rUid,
    };
  }

  return null;
};

export const getServiceAreaFormUpdateFn = (
  doUpdate: V2Gateway_UpdateServiceAreasMutationHookResult[0],
) => async (data: UpdateServiceAreaInput): Promise<{
      uid: string,
    } | null> => {
  const res = await doUpdate({
    variables: {
      data,
    }
  });

  if (res.data?.updateServiceAreas) {
    return {
      uid: data.uid
    };
  }

  return null;
};

export const getInitialValueFromFocusedMod = (
  focusedMod: ServiceArea,
): ServiceAreaFormValues => {
  const b = deleteTypeNameField<ServiceArea>(focusedMod);

  return {
    name: b.name,
    size: b.size,
    externalUid: b.externalUid,
    uid: b.uid,
  };
};

export const getServiceAreaFormInitialValue = (
  focusedMod: Maybe<ServiceArea>,
): Partial<ServiceAreaFormValues> => {
  if (focusedMod) {
    return getInitialValueFromFocusedMod(focusedMod);
  }

  return {};
};

const commonServiceAreaFormValidationSchema = {
  name: yup.string().required(),
  size: yup.string().required(),
};

export const createServiceAreaFormValidationSchema = yup.object(
  commonServiceAreaFormValidationSchema,
);

export const updateServiceAreaFormValidationSchema = yup.object({
  uid: yup.string(),
}).shape(commonServiceAreaFormValidationSchema);

export const getServiceAreaFormFormikConfig = (
  focusedMod: Maybe<ServiceArea>,
  submitForm: (values: ServiceAreaFormValues) => Promise<unknown>,
) => {
  const initialValues =
    getServiceAreaFormInitialValue(focusedMod);
  const formikCfg: FormikConfig<
    Partial<ServiceAreaFormValues>
  > = {
    initialValues,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      values,
      { resetForm },
    ) => {
      submitForm(values as ServiceAreaFormValues).then(() => {
        resetForm();
      });
    },
    validationSchema: focusedMod ?
      updateServiceAreaFormValidationSchema :
      createServiceAreaFormValidationSchema,
  };

  return formikCfg;
};

