import {
  ReferenceType,
  useV2Gateway_QueryLocationPoliciesLazyQuery,
  V1Beta3_ReferenceMatchType,
  V2Gateway_QueryLocationPoliciesQueryHookResult,
} from 'Generated/graphql';
import { useEffect, useState } from 'react';

export type UseLocationPolicies = NonNullable<
  V2Gateway_QueryLocationPoliciesQueryHookResult['data']
>['queryLocationPolicies']

const useLocationPolicies = (
  locationUids?: string[],
): UseLocationPolicies | undefined => {
  const [queryLocationPolicies, queryLocationPoliciesQuery] =
    useV2Gateway_QueryLocationPoliciesLazyQuery();
  const [policies, setLocationPolicies] = useState<UseLocationPolicies>();

  useEffect(() => {
    if (locationUids?.length) {
      queryLocationPolicies({
        variables: {
          query: {
            references: [
              {
                type: ReferenceType.External,
                ids: locationUids,
                match_type: V1Beta3_ReferenceMatchType.Any,
              },
            ]
          },
        }
      });
    }
  }, [locationUids?.join(',')]);

  useEffect(() => {
    const { data } = queryLocationPoliciesQuery;
    if (data?.queryLocationPolicies) {
      setLocationPolicies(data.queryLocationPolicies);
    }
  }, [queryLocationPoliciesQuery.data]);

  return policies;
};

export default useLocationPolicies;

