import React from 'react';
import { Text } from 'theme-ui';
import { Modal, Typography } from 'antd';
import { getLocationInviteUrl } from './functions';

type LocationInviteModalProps = {
  visible: boolean;
  onClose: () => void;
  locationUid: string
}

const LocationInviteModal: React.FC<LocationInviteModalProps> = ({
  visible,
  onClose,
  locationUid,
}) => {
  const inviteUrl = getLocationInviteUrl(locationUid);

  return (
    (<Modal
      title="Location Invite"
      open={visible}
      onOk={() => onClose()}
      onCancel={() => onClose()}
    >
      <Text
        sx={{
          display: 'block'
        }}
      >
        This link can be shared with your employees, customers, tenants,{' '}
        friends, and anyone else that you would like to be able to{' '}
        access appointments that are created at this location.
      </Text>
      <Typography.Text copyable={{
        text: inviteUrl,
      }}>
        <a
          href={inviteUrl}
        >
          {inviteUrl}
        </a>
      </Typography.Text>
    </Modal>)
  );
};

export default LocationInviteModal;
