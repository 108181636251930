import React, { Dispatch } from 'react';
import { Box, Divider } from 'theme-ui';
import LocationListItem from
  'Common/components/ManageLocations/LocationListItem';
import { AccountType } from 'Common/functions/AccountType';

type NewLocationProps = {
  newLocations: boolean[];
  isCreatingLocation: boolean;
  onUpdate?: () => void;
  onClickClose: () => void;
};

const NewLocation: React.FC<NewLocationProps> = ({
  newLocations,
  isCreatingLocation,
  onUpdate,
  onClickClose,
}) => {
  return (
    <>
      {(
        newLocations &&
        newLocations.map(
          (b, ix) => b && (
            <LocationListItem
              key={ix}
              accountType={AccountType.LocationOwner}
              manageLocationProps={{
                onClickClose,
                index: ix,
                isEditing: isCreatingLocation,
              }}
              onUpdate={() => {
                onUpdate && onUpdate();
              }}
            />
          )
        )
      )}
    </>
  );
};

export default NewLocation;

