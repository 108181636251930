type ArrayValueObject = { [key: string]: unknown[] | ArrayValueObject }

type ArrayValueObjectToArrayOptions = {
  flattenNestedIfSingle: boolean;
}

//TODO: write tests
// Transform object with array values to array of objects
export const ArrayValueObjectToArray = <
  T extends Record<string, unknown>
>(
    list: ArrayValueObject,
    opts?: ArrayValueObjectToArrayOptions,
  ): T[] => {
  const data: T[] = [];
  const key = Object.keys(list)[0];
  if (!key) {
    return data;
  }

  const processElement = (i: number) => {
    Object.keys(list).forEach(k => {
      const val = list[k];
      let elem;
      if (val.constructor === Array) {
        elem = val[i];
      } else if (typeof val === 'object') {
        elem = ArrayValueObjectToArray(val as ArrayValueObject, opts);
        elem = elem[i];
        // we may not want an array back for nested objects if they are a
        // single
        if (opts?.flattenNestedIfSingle && elem.length === 1) {
          elem = elem[0];
        }
      } else {
        throw new Error(
          'found non-array/non-object value when transforming array value \
          object'
        );
      }
      data[i] = {
        ...data[i],
        [k]: elem,
      };
    });
  };

  if (list[key].constructor === Array) {
    for (let i = 0; i < (list[key].length as number); i++) {
      processElement(i);
    }
  } else {
    processElement(0);
  }

  return data;
};

