import React, { useContext, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import {
  V2Gateway_GetLocationsQueryHookResult,
  V2Gateway_QueryLocationsQueryResult,
  V1Beta3_ReferenceMatchType,
  ReferenceType,
} from 'Generated/graphql';
import { Button, Flex } from 'theme-ui';
import ManageLocationsContext from 'Common/context/ManageLocationsContext';
import {
  FormLinkLabel,
} from 'Common/context/FormChainContextProvider';
import styles from './style';
import { AccountType } from 'Common/functions/AccountType';
import PromptCreateLocation from 'Common/components/PromptCreateLocation';
import getRelatedLocations from 'Common/functions/getRelatedLocations';
import PromptJoinLocation from 'Common/components/PromptJoinLocation';
import useLocationSubscriptions from 'Common/hooks/useLocationSubscriptions';
import useLocationSubscriptionRequests from
  'Common/hooks/useLocationSubscriptionRequests';
import { useApolloClient } from '@apollo/client';
import DeleteLocationModal from './DeleteLocationModal';
import LocationListWithProvider from './PresentLocationList';
import FilesContextProvider from 'Common/context/FilesContextProvider';
import NewLocation from './NewLocation';
import LocationSubscriptionContext from 'Common/context/LocationSubscriptionContext';

type ManageLocationsLocations = NonNullable<
  V2Gateway_GetLocationsQueryHookResult['data']
>['getLocations']

type locations = NonNullable<
  V2Gateway_QueryLocationsQueryResult['data']
>['queryLocations']['locations'];
type location = locations[number];

export type LocationListProps = {
  locations: ManageLocationsLocations;
  accountType: AccountType;
  onUpdate?: () => void;
  ownerUid: string;
}

export const LocationFormChainLinkLabel: FormLinkLabel = 'location';

const LocationList: React.FC<LocationListProps> = ({
  locations,
  accountType,
  onUpdate,
  ownerUid,
}) => {
  const {
    dispatch,
    newLocations,
  } = useContext(ManageLocationsContext);
  const {
    getLocationSubscriptionsQuery,
  } = useContext(LocationSubscriptionContext);
  const [deleteLocationUid, setDeleteLocationUid] = useState<
    string | undefined
  >();
  const [editingLocationUid, setEditingLocationUid] = useState<
    string | undefined
  >();
  const isCreatingLocation = newLocations.some(l => l);
  const [
    relatedBusinessOrPartnerLocations,
    setRelatedBusinessOrPartnerLocations,
  ] = useState<locations | undefined>();
  const [promptJoinLocationIsOpen, setPromptJoinLocationIsOpen] =
    useState(false);
  const [locationSubscriptions, refetchLocationSubscriptions] =
    useLocationSubscriptions(ownerUid ? [{
      type: ReferenceType.Owner,
      ids: [ownerUid],
      match_type: V1Beta3_ReferenceMatchType.Any,
    }] : undefined, 'network-only');
  const [locationSubscriptionRequests, refetchLocationSubscriptionRequests] =
    useLocationSubscriptionRequests(ownerUid ? [{
      type: ReferenceType.Owner,
      ids: [ownerUid],
      match_type: V1Beta3_ReferenceMatchType.Any,
    }] : undefined, 'network-only');
  const client = useApolloClient();
  const fileExternalUids = useMemo(() => locations?.flatMap(
    l => [l.uid, ...l.serviceAreas.map(sa => sa.uid)]
  ), [locations]);

  const promptJoinRelatedLocationsOrRedirect = async (
    loc: Omit<location, 'serviceAreas'>,
  ) => {
    const relatedLocations = await getRelatedLocations(
      client,
      undefined,
      loc.formattedAddress,
      loc.placeId,
    );

    if (relatedLocations.length) {
      setRelatedBusinessOrPartnerLocations(relatedLocations);
      setPromptJoinLocationIsOpen(true);
    }
  };

  const content = (
    <>
      {fileExternalUids && (
        <FilesContextProvider externalUids={fileExternalUids}>
          <LocationListWithProvider
            locations={locations}
            accountType={accountType}
            editingLocationUid={editingLocationUid}
            setEditingLocationUid={setEditingLocationUid}
            setDeleteLocationUid={setDeleteLocationUid}
            isCreatingLocation={isCreatingLocation}
            onUpdate={onUpdate}
          />
        </FilesContextProvider>
      )}
      {accountType === AccountType.LocationOwner ? (
        <NewLocation
          newLocations={newLocations}
          isCreatingLocation={isCreatingLocation}
          onUpdate={() => {
            dispatch({
              newLocations: [],
            });
            onUpdate && onUpdate();
          }}
          onClickClose={() => dispatch({
            newLocations: [],
          })}
        />
      ) : (
        <>
          <PromptCreateLocation
            isOpen={newLocations.length > 0}
            ownerUid={ownerUid}
            onClose={() => {
              dispatch({
                newLocations: [],
              });
            }}
            onFlowComplete={loc => {
              onUpdate && onUpdate();

              promptJoinRelatedLocationsOrRedirect(loc);
              dispatch({
                newLocations: [],
              });
            }}
          />
          {relatedBusinessOrPartnerLocations?.length && (
            <PromptJoinLocation
              isOpen={promptJoinLocationIsOpen}
              onClose={() => setPromptJoinLocationIsOpen(false)}
              locations={relatedBusinessOrPartnerLocations}
              existingLocationSubscriptionLocationUids={
                locationSubscriptions?.map(l => l.locationUid) || []
              }
              locationSubscriptionRequests={
                locationSubscriptionRequests || []
              }
              onChange={() => {
                refetchLocationSubscriptionRequests();
                refetchLocationSubscriptions();
                getLocationSubscriptionsQuery.refetch &&
                    getLocationSubscriptionsQuery.refetch();
              }}
            />
          )}
        </>
      )}
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          pt: '1rem',
        }}
      >
        {accountType !== AccountType.ServiceProvider &&
          (!isCreatingLocation || accountType !== AccountType.LocationOwner) &&
          !editingLocationUid && (
          <Button
            onClick={(): void => dispatch({
              newLocations: newLocations ? [...newLocations, true] : [true]
            })}
            variant={'mainFormButton'}
            sx={styles.button}
          >
              Add My Location{' '}
            <PlusOutlined />
          </Button>
        )}
      </Flex>
      <DeleteLocationModal
        deleteLocationUid={deleteLocationUid}
        onClose={() => setDeleteLocationUid(undefined)}
        onDeleted={() => {
          message.success('Location deleted');
          setDeleteLocationUid(undefined);
          onUpdate && onUpdate();
        }}
      />
    </>
  );

  return content;
};

export default LocationList;
