import React, { useContext } from 'react';
import { Form } from 'formik';
import {
  CreateLocationPolicyInput,
  LocationPolicy,
  UpdateLocationPolicyInput,
} from 'Generated/graphql';
import {
  LocationPolicyModFieldKey,
  getLocationPolicyFormFields,
  getCreateLocationPolicyFormFields,
  getUpdateLocationPolicyFormFields,
} from './functions';
import {
  default as ManageLocationPoliciesContext,
} from 'Common/context/ManageLocationPoliciesContext';
import { Skeleton } from 'antd';

export type LocationPolicyFormValues =
  CreateLocationPolicyInput | UpdateLocationPolicyInput

export type LocationPolicyFormProps = {
  existingMod?: LocationPolicy;
  hideFields?: LocationPolicyModFieldKey[]
}

const LocationPolicyForm: React.FC<LocationPolicyFormProps> = ({
  existingMod,
  hideFields,
}) => {
  const { locationPolicies } = useContext(ManageLocationPoliciesContext);

  return locationPolicies ? (
    <Form
      sx={{
        flexBasis: '85%',
      }}
    >
      {existingMod ?
        getUpdateLocationPolicyFormFields(hideFields) :
        getCreateLocationPolicyFormFields(locationPolicies)}
      {getLocationPolicyFormFields(hideFields, existingMod)}
    </Form>
  ) : <Skeleton />;
};

export default LocationPolicyForm;
