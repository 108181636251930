import React, { useState, useContext, useMemo } from 'react';
import styles from './style';
import {
  LocationType,
  ReferenceType,
  V1Beta3_ReferenceMatchType,
  V2Gateway_GetLocationsQueryHookResult as GetLocationsQueryHookResult,
  useV2Gateway_CreateLocationSubscriptionsMutation,
} from 'Generated/graphql';
import ServiceAreasForLocationsContext from
  'Common/context/ServiceAreasForLocationsContext';
import ManageServiceAreas from 'Common/components/ManageServiceAreas';
import ManageLocation, { ManageLocationProps } from './ManageLocation';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Box, Button, Flex, Heading, Spinner, Text } from 'theme-ui';
import LocationInviteModal from 'Common/components/LocationInviteModal';
import ManageLocationPolicies from 'Common/components/ManageLocationPolicies';
import ManageLocationPoliciesContextProvider from
  'Common/context/ManageLocationPoliciesContextProvider';
import ManageLocationSubscribers from
  'Common/components/ManageLocationSubscribers';
import { AccountType } from 'Common/functions/AccountType';
import { LocationFormMethods } from 'Common/components/v2/LocationForm';
import {
  ServiceAreaFormMethods
} from 'Common/components/ManageServiceAreas/Form';
import { FileFormMethods } from 'Common/components/ManageFiles/Form';
import {
  LabelForLocationType,
} from 'Common/functions/Location';

type Location = NonNullable<
  GetLocationsQueryHookResult['data']
>['getLocations'][number]

type LocationListItemProps = {
  location?: Location;
  manageLocationProps?: Partial<ManageLocationProps>;
  accountType: AccountType;
  onUpdate?: () => void;
}

const LocationListItem: React.FC<LocationListItemProps> = ({
  location,
  manageLocationProps,
  accountType,
  onUpdate,
}) => {
  const {
    serviceAreasForLocations: allServiceAreas,
    getServiceAreasForLocationsQuery,
  } = useContext(ServiceAreasForLocationsContext);
  const [
    showManageServiceAreasModal,
    setShowManageServiceAreasModal,
  ] = useState(false);
  const [
    showManageLocationPoliciesModal,
    setShowManageLocationPoliciesModal,
  ] = useState(false);
  const [
    isShowingManageLocationSubscribers,
    setIsShowingManageLocationSubscribers,
  ] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const locationLabel = location ? LabelForLocationType[location.type] : '';
  const [locationFormMethods, setLocationFormMethods] =
    useState<LocationFormMethods | null>(null);
  const [locationFileFormMethods, setLocationFileFormMethods] =
    useState<FileFormMethods | null>(null);
  const [
    createLocationSubscriptions,
  ] = useV2Gateway_CreateLocationSubscriptionsMutation();
  const isCustomer = accountType === AccountType.Customer;
  const [serviceAreaFormMethods, setServiceAreaFormMethods] =
    useState<ServiceAreaFormMethods | null>(null);
  const [serviceAreaFileFormMethods, setServiceAreaFileFormMethods] =
    useState<FileFormMethods | null>(null);
  const [isLocationSubmitButtonLoading, setIsLocationSubmitButtonLoading] =
    useState(false);
  const [
    isServiceAreaSubmitButtonLoading,
    setIsServiceAreaSubmitButtonLoading,
  ] = useState(false);
  const [showServiceAreaForm, setShowServiceArea] = useState(false);

  const serviceAreasForLocation = useMemo(() => {
    if (allServiceAreas) {
      const sasForLocation = location ? allServiceAreas.filter(
        sa => sa.externalUid === location.uid
      ) : [];
      return sasForLocation;
    }
  }, [allServiceAreas]);

  const handleOnLocationFormMethods = (methods: LocationFormMethods) => {
    setLocationFormMethods(() => methods);
  };

  const handleOnLocationFileFormMethods = (methods: FileFormMethods) => {
    setLocationFileFormMethods(() => methods);
  };

  const handleOnServiceAreaFormMethods = (methods: ServiceAreaFormMethods) => {
    setServiceAreaFormMethods(() => methods);
  };

  const handleOnServiceAreaFileFormMethods = (methods: FileFormMethods) => {
    setServiceAreaFileFormMethods(() => methods);
  };

  const handleLocationFormSubmission = async () => {
    setIsLocationSubmitButtonLoading(true);
    if (locationFormMethods) {
      await Promise.all([
        locationFormMethods.validate(),
      ]).then(async ([
        locationErrors,
      ]) => {
        const hasLocationError =
          locationErrors && Object.keys(locationErrors).length !== 0;

        if (hasLocationError) {
          console.error('Location form validation failed', locationErrors);
        } else {
          // If there are no validation errors, submit the form
          const locationRes = await locationFormMethods.submit();
          locationFormMethods.reset();
          if (locationRes && locationFileFormMethods) {
            const locationFileErrors = await locationFileFormMethods.validate();
            const hasLocationFileError = locationFileErrors &&
              Object.keys(locationFileErrors).length !== 0;
            if (hasLocationFileError) {
              console.error('File form validation failed', locationFileErrors);
            } else {
              await locationFileFormMethods.submit(locationRes.uid);
              locationFileFormMethods.reset();
            }

            // TODO: do this in a consumer server side
            if (!isCustomer) {
              await createLocationSubscriptions({
                variables: {
                  data: {
                    locationUid: locationRes.uid,
                  }
                }
              });
            }
          }

          onUpdate && onUpdate();
        }
      });
    }

    setIsLocationSubmitButtonLoading(false);
  };

  const handleServiceAreaFormSubmission = async (locationUid: string) => {
    setIsServiceAreaSubmitButtonLoading(true);
    if (serviceAreaFormMethods && serviceAreaFileFormMethods) {
      await Promise.all([
        serviceAreaFormMethods.validate(),
      ]).then(async ([
        serviceAreaErrors,
      ]) => {
        const hasServiceAreaError =
          serviceAreaErrors && Object.keys(serviceAreaErrors).length !== 0;

        if (hasServiceAreaError) {
          console.error(
            'Service area form validation failed',
            serviceAreaErrors,
          );
        } else {
          // If there are no validation errors, submit the form
          const serviceAreaRes =
            await serviceAreaFormMethods.submit(locationUid);
          serviceAreaFormMethods.reset();
          if (serviceAreaRes) {
            const serviceAreaFileErrors =
              await serviceAreaFileFormMethods.validate();
            const hasLocationFileError = serviceAreaFileErrors &&
              Object.keys(serviceAreaFileErrors).length !== 0;
            if (hasLocationFileError) {
              console.error(
                'Service Area File form validation failed',
                serviceAreaFileErrors,
              );
            } else {
              await serviceAreaFileFormMethods.submit(serviceAreaRes.uid);
              serviceAreaFileFormMethods.reset();
            }
          }

          setShowServiceArea(false);
          setShowManageServiceAreasModal(false);
          getServiceAreasForLocationsQuery.refetch &&
            getServiceAreasForLocationsQuery.refetch();
        }
      });
    }

    setIsServiceAreaSubmitButtonLoading(false);
  };

  return (
    <>
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex sx={styles.locationHeader}>
          <Box sx={{
            maxWidth: '75%'
          }}>
            {location && (
              <>
                <Heading variant="body2Bold">
                  {locationLabel}
                </Heading>
                <Flex sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}>
                  <Text variant="body2" sx={{ pr: '0.2rem' }}>
                    {location.formattedAddress}
                  </Text>
                </Flex>
              </>
            )}
          </Box>
          <Flex sx={styles.locationHeaderButtons}>
            {location && (location.type === LocationType.Business ||
            location.type === LocationType.Partner) && (
              <Button
                key="0"
                onClick={(): void => {
                  setShowInviteModal(true);
                }}
                variant='mainFormButton'
                sx={{
                  mt: '0.5rem'
                }}
              >
              Share
              </Button>
            )}
            <Flex sx={{
              alignItems: 'justify-between',
              gap: '1rem'
            }}>
              {manageLocationProps?.onClickDelete && location && (
                <Button
                  key="1"
                  onClick={(ev): void => {
                    manageLocationProps?.onClickDelete &&
                    manageLocationProps?.onClickDelete();
                    ev.stopPropagation();
                  }}
                  variant="plainText"
                  sx={{
                    display: location &&
                    location.type === LocationType.ServiceProvider ?
                      'none' :
                      'block'
                  }}
                >
                Delete
                </Button>
              )}
              <>
                {!manageLocationProps?.hideEditButton && location &&
                (manageLocationProps?.isEditing ? (
                  <Button
                    sx={styles.locationHeaderButtonMargin}
                    variant="plainText"
                    onClick={(): void => manageLocationProps?.onClickClose &&
                      manageLocationProps?.onClickClose()}
                  >
                    <CloseOutlined />
                  </Button>
                ) : (
                  <Button
                    sx={styles.locationHeaderButtonMargin}
                    variant="plainText"
                    onClick={(): void => manageLocationProps?.onClickEdit &&
                      manageLocationProps?.onClickEdit()}
                  >
                    Edit
                  </Button>
                ))}
              </>
            </Flex>
          </Flex>
        </Flex>
        <Modal
          title={`Manage your ${accountType === AccountType.Customer ?
            'home ' :
            ''}location`}
          open={manageLocationProps?.isEditing}
          onCancel={() => {
            if (manageLocationProps?.onClickClose) {
              manageLocationProps.onClickClose();
            }
          }}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Flex
            data-cy="locationlisting-wrap"
            sx={{
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <ManageLocation
              onLocationMethods={handleOnLocationFormMethods}
              onFileMethods={handleOnLocationFileFormMethods}
              accountType={accountType}
              location={location}
              onClickServiceAreas={() => {
                setShowManageServiceAreasModal(true);
              }}
              onClickLocationPolicies={() => {
                setShowManageLocationPoliciesModal(true);
              }}
              onClickLocationSubscribers={() => {
                setIsShowingManageLocationSubscribers(true);
              }}
              {...manageLocationProps}
            />
            <Button onClick={handleLocationFormSubmission}>
              {isLocationSubmitButtonLoading ? (
                <Spinner size={32} />
              ) : 'Save'}
            </Button>
          </Flex>
        </Modal>
      </Flex>
      {location && (
        <LocationInviteModal
          onClose={() => setShowInviteModal(false)}
          visible={showInviteModal}
          locationUid={location.uid}
        />
      )}
      {location && serviceAreasForLocation && (
        <Modal
          title="Service Areas"
          open={showManageServiceAreasModal}
          onCancel={() => {
          //setShowForm(false);
          //setFocusedServiceArea(undefined);
            setShowManageServiceAreasModal(false);
          }}
          cancelButtonProps={{
            style: {
              display: 'none',
            }
          }}
          okButtonProps={{
            style: {
              display: 'none',
            }
          }}
        >
          <ManageServiceAreas
            showForm={showServiceAreaForm}
            serviceAreas={serviceAreasForLocation}
            externalUid={location.uid}
            onServiceAreaMethods={handleOnServiceAreaFormMethods}
            onFileMethods={handleOnServiceAreaFileFormMethods}
            onClickSubmit={() => handleServiceAreaFormSubmission(location.uid)}
            onClickCreateServiceArea={() => setShowServiceArea(true)}
            isLoading={isServiceAreaSubmitButtonLoading}
          />
          <Flex sx={{ justifyContent: 'center', mt: '1rem', }}>
            <Flex sx={{
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}>
            </Flex>
          </Flex>
        </Modal>
      )}
      {location && (
        <>
          <ManageLocationPoliciesContextProvider
            references={[{
              type: ReferenceType.External,
              ids: [location.uid],
              match_type: V1Beta3_ReferenceMatchType.Any,
            }]}
          >
            <ManageLocationPolicies
              externalUid={location.uid}
              showModal={showManageLocationPoliciesModal}
              onCancel={() => setShowManageLocationPoliciesModal(false)}
              onSubmit={() => setShowManageLocationPoliciesModal(false)}
            />
          </ManageLocationPoliciesContextProvider>
          <Modal
            open={isShowingManageLocationSubscribers}
            onCancel={() => setIsShowingManageLocationSubscribers(false)}
            okButtonProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Box sx={{ pt: '1rem', }} >
              <ManageLocationSubscribers locationUid={location.uid} />
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default LocationListItem;
