import React, { useContext, useEffect, useState } from 'react';
import { Field, Text, Button, Flex, Box, Divider } from 'theme-ui';
import {
  LocationType,
  ReferenceType,
  useV2Gateway_QueryLocationsLazyQuery,
  V1Beta3_ReferenceMatchType,
  V2Gateway_QueryLocationsLazyQueryHookResult,
} from 'Generated/graphql';
import LocationSelectListItem, {
  LocationSelectListItemProps,
  LocationWithLatLng,
} from 'Common/components/LocationSelectListItem';
import {
  default as CustomerLocationsDashboardContext,
} from 'Common/context/CustomerLocationsDashboardContext';
import {
  getDeDupedByUid,
} from 'Common/functions/Mod/functions';
import CustomerHomeContext from 'Common/context/CustomerHomeContext';
import InfiniteScroll from 'react-infinite-scroller';
import ManageLocationsContext from 'Common/context/ManageLocationsContext';
import useLocationPolicies from 'Common/hooks/useLocationPolicies';
import { search as searchFn } from 'fast-fuzzy';
import LocationSubscriptionContext from
  'Common/context/LocationSubscriptionContext';
import useLocationSubscriptionRequests from
  'Common/hooks/useLocationSubscriptionRequests';
import IdentityContext from 'Common/context/IdentityContext';
import SubscribeToLocationButton from '../SubscribeToLocationButton';
import { message } from 'antd';
import { trimFormattedAddress } from 'Common/functions/Location';

export type Locations = NonNullable<
  V2Gateway_QueryLocationsLazyQueryHookResult[1]['data']
>['queryLocations']['locations']

export type LocationSelectProps<
  T extends LocationWithLatLng = LocationWithLatLng
> = {
  locationListItemProps?: Partial<
    Omit<
      LocationSelectListItemProps<T>, 'location'
    >
  >;
  filter?: (l: Locations[number]) => boolean;
  locations?: Locations;
}

const getNoResultsMessage = (
  onClick: () => void,
) => (
  <Flex
    sx={{
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Text variant="text.paragraph">
      Your location is not registered!
      You&apos;ll need to register it before you can book an appointment.
      It only takes a few clicks.
    </Text>
    <Button
      variant={'mainFormButton'}
      onClick={() => onClick()}
    >
      Register Location
    </Button>
  </Flex>
);

const LocationSelect: React.FC<LocationSelectProps> = ({
  locationListItemProps,
  filter,
  locations: pLocations,
}) => {
  //const [geo] = useGeoLocation();
  const [search, setSearch] = useState<string | undefined>();
  const [
    queryLocations,
    queryLocationsQuery,
  ] = useV2Gateway_QueryLocationsLazyQuery();
  const [
    loadedLocations,
    setLoadedLocations,
  ] = useState<Locations | undefined>();
  const [
    filteredLocations,
    setFilteredLocations,
  ] = useState<Locations | undefined>(pLocations);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const {
    dispatch,
  } = useContext(CustomerLocationsDashboardContext);
  const {
    dispatch: mlDispatch,
    newLocations,
  } = useContext(ManageLocationsContext);
  const {
    dispatch: chDispatch,
  } = useContext(CustomerHomeContext);
  const [page, setPage] = useState(1);
  const policies = useLocationPolicies(filteredLocations?.map(l => l.uid));
  const {
    locations: allLocations,
    getLocationSubscriptionsQuery,
  } = useContext(LocationSubscriptionContext);
  const { ownerUid } = useContext(IdentityContext);

  const [locationSubscriptionRequests, refetchlocationSubscriptionRequests] =
    useLocationSubscriptionRequests(ownerUid ? [{
      ids: [ownerUid],
      type: ReferenceType.Owner,
      match_type: V1Beta3_ReferenceMatchType.Any,
    }] : undefined);

  useEffect(() => {
    if (search && search.length >= 3) {
      queryLocations({
        variables: {
          query: {
            search,
            //discoverableFromLat: geo?.coords.latitude || 0,
            //discoverableFromLng: geo?.coords.longitude || 0,
            types: [LocationType.Business, LocationType.Partner],
            rangeKms: 100,
            page,
            pageSize: 25,
          },
        }
      });
    }
  }, [search, page]);

  useEffect(() => {
    const { data } = queryLocationsQuery;
    if (data?.queryLocations) {
      const allLocs = loadedLocations ? [
        ...loadedLocations,
        ...data.queryLocations.locations,
      ] : data.queryLocations.locations;

      const dedupedLocs = getDeDupedByUid(allLocs).sort(
        (a, b) => a.name > b.name ? 1 : -1
      );

      setLoadedLocations(dedupedLocs);
      setHasMoreResults(data.queryLocations.hasMoreResults);
    }
  }, [queryLocationsQuery.data]);

  useEffect(() => {
    if (pLocations !== undefined) {
      return;
    }

    if (loadedLocations) {
      const flocs = filter ? loadedLocations.filter(filter) : loadedLocations;
      if (search) {
        const fLocs = searchFn(search, loadedLocations.map(m => ({
          name: `${m.name} ${trimFormattedAddress(m.formattedAddress)}`,
          value: m,
        })), {
          keySelector: l => l.name,
        }).map(r => r.value);
        setFilteredLocations(fLocs);
      } else {
        return setFilteredLocations(flocs);
      }
    } else {
      setFilteredLocations([]);
    }
  }, [search, loadedLocations, Boolean(filter), pLocations]);

  return (
    <>
      {pLocations === undefined && (
        <Field
          label="Search for your shared space"
          name=""
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          sx={{
            flexBasis: '20%',
            '&::placeholder': {
              fontStyle: 'italic',
            }
          }}
        />
      )}
      {/*getGeoAccessAlert(Boolean(geoError))*/}
      <Flex
        sx={{
          flexDirection: 'column',
          overflow: 'auto',
          maxHeight: '20rem',
        }}
      >
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => {
            queryLocationsQuery.loading ||
              (hasMoreResults && setPage(page + 1));
          }}
          hasMore={!queryLocationsQuery.loading && hasMoreResults}
          useWindow={false}
        >
          {policies && (filteredLocations && filteredLocations.length) ?
            filteredLocations.map(l => (
              <Box key={l.uid}>
                <LocationSelectListItem
                  location={l}
                  policies={policies}
                  {...locationListItemProps}
                >
                  {locationListItemProps?.canJoin &&
                    locationSubscriptionRequests && (
                    <SubscribeToLocationButton
                      existingLocationSubscriptionLocationUids={
                        allLocations?.map(l => l.uid)
                      }
                      locationUid={l.uid}
                      locationPolicies={policies}
                      locationSubscriptionRequests={
                        locationSubscriptionRequests
                      }
                      onCreateLocationSubscription={() => {
                        message.success('Subscribed!');
                        getLocationSubscriptionsQuery.refetch();
                      }}
                      onCreateLocationSubscriptionRequest={() => {
                        message.info('Subscription Requested');
                        refetchlocationSubscriptionRequests();
                      }}
                      onDeleteLocationSubscription={() => {
                        message.info('Subscription Removed');
                        getLocationSubscriptionsQuery.refetch();
                      }}
                      onDeleteLocationSubscriptionRequest={() => {
                        message.info('Request Cancelled');
                        refetchlocationSubscriptionRequests();
                      }}
                    />
                  )}
                </LocationSelectListItem>
                <Divider />
              </Box>
            )) : search && search.length > 2 && getNoResultsMessage(() => {
              if (dispatch) {
                dispatch({
                  showManageLocationsCard: true,
                });
              }

              if (mlDispatch) {
                mlDispatch({
                  newLocations: newLocations ? [...newLocations, true] : [true]
                });
                // Hack to ensure the location form is in view
                setTimeout(() => {
                  const locForm = document.getElementById('location-form');
                  if (locForm) {
                    locForm.scrollIntoView({
                      behavior: 'smooth'
                    });
                  }
                }, 1000);
              }

              if (chDispatch) {
                chDispatch({
                  showNewLocationSubscription: false,
                });
              }
            })}
        </InfiniteScroll>
      </Flex>
    </>
  );
};

export default LocationSelect;
