import React from 'react';
import { Heading, Text } from 'theme-ui';

const getListItemMeta = (
  title: React.ReactNode,
) => (
  description?: React.ReactNode,
): React.ReactNode => (
  <>
    <Heading as="h4" sx={{ fontWeight: 'bold', }}>
      {title}
    </Heading>
    {description}
  </>
);

const getListItemMetaDesciption = (
  description: React.ReactNode,
): React.ReactNode => (
  <Text as="small">
    {description}
  </Text>
);

type ListItemProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

const ListItem: React.FC<ListItemProps> = ({
  title,
  description,
  children,
}) => {
  const metaTitle = getListItemMeta(title);
  const meta = description ?
    metaTitle(getListItemMetaDesciption(description)) :
    metaTitle();

  return <>
    {meta}
  </>;
};

export default ListItem;
