import React from 'react';
import {
  Location,
  UpdateLocationInput,
  CreateLocationInput,
  Maybe,
  V2Gateway_CreateLocationsMutationHookResult,
  V2Gateway_UpdateLocationsMutationHookResult,
  LocationType,
} from 'Generated/graphql';
import { Box } from 'theme-ui';
import {
  CreateLocationMutationReturn,
  LocationFormValues
} from '.';
import { deleteTypeNameField } from 'Common/functions/Form';
import { FormikConfig } from 'formik';
import {
  FormChainLinkSubmitFuncReturn,
} from 'Common/context/FormChainContextProvider';
import { submitData } from 'Common/functions/FormChain/submit';
import * as yup from 'yup';
import { message } from 'antd';
import PlaceIdField from './field/PlaceIdField';
import TypeField from './field/TypeField';
import PhoneNumberField from './field/PhoneNumberField';
import NameField from './field/NameField';
import UidField from './field/UidField';
import ExtendedAddressField from './field/ExtendedAddressField';
import {
  ManageLocationsLocation
} from '../ManageLocations/LocationListItem/ManageLocation';

export enum LocationModFieldKey {
  Type = '0',
  Uid = '1',
  Name = '2',
  PlaceId = '3',
  ExtendedAddress = '4',
  PhoneNumber = '5',
}

type ModVal = keyof CreateLocationInput | keyof UpdateLocationInput

type ExistingMod = Pick<
  Location,
  'uid' | 'placeId' | 'name' | 'type' | 'phoneNumber' | 'externalUid' |
  'formattedAddress'
>

const LocationModFields: Record<
  LocationModFieldKey,
  ModVal
> = {
  [LocationModFieldKey.Uid]: 'uid',
  [LocationModFieldKey.Name]: 'name',
  [LocationModFieldKey.Type]: 'type',
  [LocationModFieldKey.PlaceId]: 'placeId',
  [LocationModFieldKey.ExtendedAddress]: 'extendedAddress',
  [LocationModFieldKey.PhoneNumber]: 'phoneNumber',
};

const getUidField = (
  ff: ModVal,
): React.ReactNode => {
  return <UidField name={ff} />;
};

const getNameField = (
  ff: ModVal,
): React.ReactNode => {
  return <NameField name={ff} />;
};

const getPhoneNumberField = (
  ff: ModVal,
): React.ReactNode => {
  return <PhoneNumberField name={ff} />;
};

const getTypeField = (
  ff: ModVal,
  hideTypeOptions?: LocationType[],
): React.ReactNode => {
  return <TypeField name={ff} hideOptions={hideTypeOptions} />;
};

const getPlaceIdField = (
  ff: ModVal,
  existingMod?: ExistingMod,
): React.ReactNode => {
  return (
    <PlaceIdField name={ff} defaultAddress={existingMod?.formattedAddress} />
  );
};

const getExtendedAddressField = (
  ff: ModVal,
): React.ReactNode => {
  return (
    <ExtendedAddressField name={ff} />
  );
};

const getCreateModFormItems = (
  hideFields: LocationModFieldKey[],
  hideTypeOptions?: LocationType[],
) => {
  const allItems = Object.entries(LocationModFields).map(
    ([k, ff]) => {
      // hard-cast because Object.entries typing sucks
      const ffk = k as unknown as LocationModFieldKey;
      const isHidden = hideFields.includes(ffk);
      const el = (() => {
        switch (ffk) {
        case LocationModFieldKey.Name:
          return getNameField(ff);
        case LocationModFieldKey.PhoneNumber:
          return getPhoneNumberField(ff);
        case LocationModFieldKey.Type:
          return getTypeField(ff, hideTypeOptions);
        case LocationModFieldKey.PlaceId:
          return getPlaceIdField(ff);
        case LocationModFieldKey.ExtendedAddress:
          return getExtendedAddressField(ff);
        }
      })();
      return (
        <Box key={ff} sx={isHidden ? { display: 'none' } : undefined}>
          {el}
        </Box>
      );
    });

  return allItems;
};

const getUpdateModFormItems = (
  hideFields: LocationModFieldKey[],
  existingMod?: ExistingMod | undefined,
  hideTypeOptions?: LocationType[],
) => {
  const allItems = Object.entries(LocationModFields).map(
    ([k, ff]) => {
      // hard-cast because Object.entries typing sucks
      const ffk = k as unknown as LocationModFieldKey;
      if (!hideFields.includes(ffk)) {
        switch (ffk) {
        case LocationModFieldKey.Uid:
          return getUidField(ff);
        case LocationModFieldKey.Name:
          return getNameField(ff);
        case LocationModFieldKey.PhoneNumber:
          return getPhoneNumberField(ff);
        case LocationModFieldKey.Type:
          return getTypeField(ff, hideTypeOptions);
        case LocationModFieldKey.PlaceId:
          return getPlaceIdField(ff, existingMod);
        case LocationModFieldKey.ExtendedAddress:
          return getExtendedAddressField(ff);
        }
      }
    });

  return allItems;
};

export const getFormItems = (
  hideFields: LocationModFieldKey[],
  existingMod?: ExistingMod | undefined,
  hideTypeOptions?: LocationType[],
) => {
  if (existingMod) {
    return getUpdateModFormItems(hideFields, existingMod, hideTypeOptions);
  }

  return getCreateModFormItems(hideFields, hideTypeOptions);
};

export const getInitialValueFromFocusedMod = (
  focusedMod: Omit<ManageLocationsLocation, 'serviceAreas'>,
): LocationFormValues => {
  const b = deleteTypeNameField<
    Omit<ManageLocationsLocation, 'serviceAreas'>
  >(focusedMod);

  return {
    type: b.type,
    name: b.name,
    phoneNumber: b.phoneNumber,
    uid: b.uid,
    placeId: b.placeId,
    extendedAddress: b.extendedAddress,
  };
};

export const getLocationFormInitialValue = (
  focusedMod: Maybe<Omit<ManageLocationsLocation, 'serviceAreas'>>,
): Partial<LocationFormValues> => {
  if (focusedMod) {
    return getInitialValueFromFocusedMod(focusedMod);
  }

  return {};
};

type createFn = (data: CreateLocationInput[]) => Promise<
    FormChainLinkSubmitFuncReturn<{
      internalUids: string[],
    }>
  >
export const getLocationFormCreateFn = (
  doCreate: V2Gateway_CreateLocationsMutationHookResult[0],
): createFn => async (data: CreateLocationInput[]): Promise<
    FormChainLinkSubmitFuncReturn<{
      internalUids: string[],
    }>
  > => {
  if (data.length) {
    const res = await doCreate({
      variables: {
        data,
      }
    });

    if (res.data?.createLocations) {
      return [[{
        internalUids: res.data.createLocations.internalUids,
      }], true];
    }

    message.warning('Failed to create locations');

    return [null, false];
  }

  return [null, true];
};

type updateFn = (data: UpdateLocationInput[]) => Promise<
    FormChainLinkSubmitFuncReturn<{
      internalUids: string[],
    }>
  >
export const getLocationFormUpdateFn = (
  doUpdate: V2Gateway_UpdateLocationsMutationHookResult[0],
): updateFn => async (data: UpdateLocationInput[]): Promise<
    FormChainLinkSubmitFuncReturn<{
      internalUids: string[],
    }>
  > => {
  if (data.length) {
    const res = await doUpdate({
      variables: {
        data,
      }
    });

    if (res.data?.updateLocations) {
      return [[{ internalUids: data.map(d => d.uid) }], true];
    }

    message.warning('Failed to update locations');

    return [null, false];
  }

  return [null, true];
};

export const getLocationFormSubmitFn = (
  createFn: createFn,
  updateFn: updateFn,
) => async (data: LocationFormValues[]) => {
  const res = await submitData<
      CreateLocationInput,
      UpdateLocationInput,
      CreateLocationMutationReturn,
      CreateLocationMutationReturn
    >(
      data,
      d => createFn(d),
      d => updateFn(d),
    );

  return res;
};

const commonLocationFormValidationSchema = {
  name: yup.string().required(),
  type: yup.string().required(),
  phoneNumber: yup.string(),
  placeId: yup.string().required(),
  extendedAddress: yup.string().optional(),
};

export const createLocationFormValidationSchema = yup.object(
  commonLocationFormValidationSchema,
);

export const updateLocationFormValidationSchema = yup.object({
  uid: yup.string(),
}).shape(commonLocationFormValidationSchema);

export const getLocationFormFormikConfig = (
  focusedMod: Maybe<Omit<ManageLocationsLocation, 'serviceAreas'>>,
  submitForm: (values: LocationFormValues[]) => Promise<unknown>,
) => {
  const initialValues =
    getLocationFormInitialValue(focusedMod);
  const formikCfg: FormikConfig<
    Partial<LocationFormValues>
  > = {
    initialValues,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      values,
      { resetForm },
    ) => {
      submitForm([values as LocationFormValues]).then(() => {
        resetForm();
      });
    },
    validationSchema: focusedMod ?
      updateLocationFormValidationSchema :
      createLocationFormValidationSchema,
  };

  return formikCfg;
};
