import React, { useEffect, useState, useContext } from 'react';
import { Modal, Popover, Skeleton } from 'antd';
import classes from './style';
import {
  useV2Gateway_ConnectStripeAccountMutation as useConnectStripeAccountMutation,
} from 'Generated/graphql';
import IdentityContext from 'Common/context/IdentityContext';
import UserContext from 'Common/context/UserContext';
import CountrySelectModal from 'Common/components/CountrySelectModal';
import { Button, Flex, Link, Text } from 'theme-ui';

type StripeModalProps = {
  onCancel?: () => void;
  visible: boolean;
  returnToView?: string;
}

const StripeConnectModal: React.FC<StripeModalProps> = ({
  onCancel,
  visible,
  returnToView = '0',
}) => {
  const [connectStripe, connectStripeQuery] = useConnectStripeAccountMutation();
  const [stripeExpressUrl, setStripeExpressUrl] = useState('');
  const [stripeStandardUrl, setStripeStandardUrl] = useState('');
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');
  const identityContext = useContext(IdentityContext);
  const [stripeClientId, setStripeClientId] = useState<string | undefined>();
  const { user } = useContext(UserContext);
  const [showCountryModal, setShowCountryModal] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.country === 'CA') {
      setStripeClientId(process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID);
    } else if (user.country === 'US') {
      setStripeClientId(process.env.NEXT_PUBLIC_STRIPE_US_CLIENT_ID);
    } else {
      setShowCountryModal(true);
    }
  }, [user]);

  useEffect(() => {
    const { ownerUid, fullName, emailAddress } = identityContext;
    if (ownerUid && fullName && emailAddress && stripeClientId) {
      const [firstName, lastName] = fullName.split(' ');
      setStripeExpressUrl(`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&state=${null}&redirect_uri=${encodeURIComponent(`${window.location.origin}/stripe-oauth-callback`)}&stripe_user[email]=${emailAddress}&stripe_user[business_type]=individual&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&state=${encodeURIComponent(`view=${returnToView}`)}`);

      setStripeStandardUrl(`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&state=${null}&redirect_uri=${encodeURIComponent(`${window.location.origin}/stripe-oauth-callback`)}&stripe_user[email]=${emailAddress}&stripe_user[business_type]=individual&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&stripe_landing=login&state=${encodeURIComponent(`view=${returnToView}`)}`);
    }
  }, [identityContext, stripeClientId]);

  useEffect(() => {
    if (code) {
      connectStripe({
        variables: {
          code
        }
      });
    }
  }, [code]);

  return (<>
    <Modal
      style={{
        textAlign: 'center',
      }}
      open={visible || Boolean(code)}
      title='Connect a Stripe Account'
      onCancel={() => onCancel && onCancel()}
      okButtonProps={{
        style: { display: 'none' }
      }}
      cancelButtonProps={{
        style: { display: 'none' }
      }}
    >
      <Text as="p">
        Link a Stripe account so you can receive commission payouts.
      </Text>
      <Text as="p">
        If you don&apos;t already have a Stripe account you will be guided
        through the express signup process.
      </Text>
      <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
        {connectStripeQuery.loading ? (
          <Skeleton />
        ) : (
          <Popover content={(
            <Flex sx={classes.stripePopover}>
              <Link href={stripeStandardUrl} >
                <Button sx={{ mr: '1rem' }} variant="buttons.secondary">
                  Yes
                </Button>
              </Link>
              <Link href={stripeExpressUrl}>
                <Button variant="buttons.secondary">
                  No
                </Button>
              </Link>
            </Flex>
          )} title="Do You Have A Stripe Account?" trigger="click">
            <Button
              variant="buttons.primary"
              disabled={identityContext.ownerUid === null}
              sx={{ my: '1rem' }}
            >
              Connect Stripe Account
            </Button>
          </Popover>
        )}
        <Button
          variant={'plainText'}
          sx={{ mt: '0.5rem' }}
          onClick={() => onCancel && onCancel()}
        >
          I&apos;ll do this later
        </Button>
      </Flex>
    </Modal>
    <CountrySelectModal
      visible={showCountryModal}
      onSubmit={() => setShowCountryModal(false)}
    />
  </>);
};

export default StripeConnectModal;
