export const submitData = async <
  C extends Record<string, unknown>,
  U extends Record<string, unknown> | null,
  R extends Record<string, unknown>  | null,
  UR extends Record<string, unknown> | null,
>(
  data: NonNullable<(C | U)>,
  create: (data: C) => Promise<R>,
  update: ((data: U) => Promise<UR>),
): Promise<R | UR> => {
  if ((data as C).uid) {
    const updateRes = await update(data as U);
    return updateRes;
  } else {
    const createRes = await create(data as C);
    return createRes;
  }
};

type input = Record<string, unknown>
type responseI = Record<string, unknown> | null;

type defaultCreateFn<
  D extends input,
  R extends responseI
> = (d: D) => Promise<R>

export const getSubmitFn = <
  response extends responseI,
  createInput extends input,
  updateInput extends input,
  createFn extends defaultCreateFn<createInput, response>,
  updateFn extends defaultCreateFn<updateInput, response>
  >(
    createFn: createFn,
    updateFn: updateFn,
  ) => async (data: createInput | updateInput) => {
    const res = await submitData<
      createInput,
      updateInput,
      response,
      response
    >(
      data,
      d => createFn(d),
      d => updateFn(d),
    );

    return res;
  };

export const getListSubmitFn = <
  response extends responseI,
  createInput extends input[],
  updateInput extends input[],
  createFn extends defaultCreateFn<createInput[number], response>,
  updateFn extends defaultCreateFn<updateInput[number], response>
  >(
    createFn: createFn,
    updateFn: updateFn,
  ) => async (
    createData: createInput,
    updateData: updateInput
  ) => {
    const cps = createData.map(d => createFn(d));
    const ups = updateData.map(d => updateFn(d));

    const res = await Promise.all([
      ...cps,
      ...ups,
    ]);

    return res;
  };

