import {
  Flex, Label, Text, Box
} from 'theme-ui';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { CreateLocationInput } from 'Generated/graphql';
import GooglePlacesAutocomplete from
  'Common/components/GooglePlacesAutocomplete';

type PlaceIdFieldProps = {
  name: string;
  defaultAddress?: string;
}

const PlaceIdField: React.FC<PlaceIdFieldProps> = ({
  name,
  defaultAddress,
}) => {

  const fieldFn =
    ({
      form,
      meta,
    }: FieldProps<CreateLocationInput['placeId']>) => {
      return (
        <Flex
          sx={{
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Label>
            <Box
              sx={meta.error ? {
                '& input': {
                  borderColor: 'red',
                }
              } : undefined}
            >
              <GooglePlacesAutocomplete
                data-testid="locationForm_placeIdInput"
                defaultAddress={defaultAddress}
                onSelect={p => {
                  if (p) {
                    form.setFieldValue(name, p.place_id);
                  }
                }}
              />
            </Box>
          </Label>
          {meta.error && (
            <Text as="small" variant="text.error">
              Please enter the address for this location and select the location
            from the list below.
            </Text>
          )}
        </Flex>
      );
    };

  return (
    <>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          width: '100%',
          justifyContent: 'space-evenly',
        }}
      >
        <Field
          label=""
          name={name}
          style={{
            width: '100%',
          }}
          validate={(v: string) => !v ?
            'Please enter the address for this location and select the location from the list below.' :
            undefined}
        >
          {fieldFn}
        </Field>
      </Flex>
    </>
  );
};

export default PlaceIdField;

