export const getScriptElement = (
  attempts: number,
  src: string,
  onLoad: () => void,
  cb: (s: HTMLScriptElement | null) => void,
  onError?: () => void,
) => {
  if (attempts > 5) {
    cb(null);
    return;
  }

  const s = document.createElement('script');
  s.setAttribute('src', src);
  s.onload = () => onLoad();
  s.onerror = function() {
    this.remove();
    getScriptElement(attempts + 1, src, onLoad, cb);
    onError && onError();
  };

  cb(s);
};


