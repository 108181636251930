import { Skeleton, Typography } from 'antd';
import useLocationSubscriptionRequests from
  'Common/hooks/useLocationSubscriptionRequests';
import useLocationSubscriptions from 'Common/hooks/useLocationSubscriptions';
import useUsers, { UseUsers } from 'Common/hooks/useUsers';
import {
  ReferenceType,
  V1Beta3_ReferenceMatchType,
} from 'Generated/graphql';
import { useMemo } from 'react';
import { Box, Divider, Flex, Heading, Text } from 'theme-ui';
import { getLocationInviteUrl } from '../LocationInviteModal/functions';
import LocationSubscriberListItem from './ListItem';

type ManageLocationSubscribersProps = {
  locationUid: string;
}

const ManageLocationSubscribers: React.FC<ManageLocationSubscribersProps> = ({
  locationUid,
}) => {
  const [locationSubscriberRequests, refetchLocationSubscriptionRequests] =
    useLocationSubscriptionRequests([{
      type: ReferenceType.External,
      ids: [locationUid],
      match_type: V1Beta3_ReferenceMatchType.Any,
    }]);
  const [locationSubscribers, refetchLocationSubscribers] =
    useLocationSubscriptions([{
      type: ReferenceType.External,
      ids: [locationUid],
      match_type: V1Beta3_ReferenceMatchType.Any,
    }]);
  const inviteUrl = getLocationInviteUrl(locationUid);

  const subscriberOwnerUids = useMemo(() => {
    if (locationSubscribers && locationSubscriberRequests) {
      return locationSubscribers.map(
        s => s.ownerUid
      ).concat(
        locationSubscriberRequests.map(s => s.locationSubscription.ownerUid)
      );
    }
  }, [locationSubscribers, locationSubscriberRequests]);
  const subscriberUsers = useUsers(subscriberOwnerUids);

  const getContent = () => {
    if (
      !(locationSubscribers && locationSubscriberRequests)
    ) {
      return <Skeleton />;
    }

    return (
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {Boolean(locationSubscriberRequests.length) && (
            <Box
              sx={{
                border: '1px gray solid',
                p: '0.5rem',
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Heading as="h4">
                  New Requests!
                </Heading>
                <Text>
                  Approve the members you would like to join your location, reject
                  the ones you do not.
                </Text>
              </Box>
              <Divider />
              {subscriberUsers && locationSubscriberRequests.map(l => {
                const user = subscriberUsers.find(
                  u => u.uid === l.locationSubscription.ownerUid
                ) as UseUsers[number];

                return (
                  <LocationSubscriberListItem
                    key={user.uid}
                    user={user}
                    locationSubscriptionRequest={l}
                    onUpdate={() => {
                      refetchLocationSubscriptionRequests();
                      refetchLocationSubscribers();
                    }}
                  />
                );
              })}
            </Box>
          )}
          <Box
            sx={{
              p: '0.5rem 0',
            }}
          >
            {subscriberUsers && locationSubscribers.map(l => {
              const user = subscriberUsers.find(
                u => u.uid === l.ownerUid
              ) as UseUsers[number];

              return (
                <LocationSubscriberListItem
                  key={user.uid}
                  user={user}
                  locationSubscription={l}
                  onUpdate={() => {
                    refetchLocationSubscriptionRequests();
                    refetchLocationSubscribers();
                  }}
                />
              );
            })}
          </Box>
        </Flex>
        <Divider />
        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            mt: '0.5rem',
            pt: '0.5rem',
          }}
        >
          <Text as="p">
            Share this link to invite others to join this location!
          </Text>
          <Typography.Text copyable={{
            text: inviteUrl,
          }}>
            <a
              href={inviteUrl}
            >
              {inviteUrl}
            </a>
          </Typography.Text>
        </Flex>
      </Flex>
    );
  };

  return getContent();
};

export default ManageLocationSubscribers;

