import { Field, FieldAttributes, FieldProps } from 'formik';
import {
  CreateLocationPolicyInput,
  LocationPolicyType,
  LocationPolicyValueType,
} from 'Generated/graphql';
import { Label, Select, Text } from 'theme-ui';
import {
  LocationPolicyModFieldKey,
  LocationPolicyModFields,
} from '../functions';

type LocationPolicyTypeSelectProps = {
  name: string;
  filter?: (v: LocationPolicyType) => boolean;
}

const LocationPolicyPolicyTypeValueTypeMap: Record<LocationPolicyType, LocationPolicyValueType> = {
  [LocationPolicyType.SubscriberAppointmentAutomaticApproval]: LocationPolicyValueType.Boolean,
  [LocationPolicyType.SubscriptionAutomaticApproval]: LocationPolicyValueType.Boolean,
};

const LocationPolicyPolicyTypeDefaultValueMap: Record<LocationPolicyType, string> = {
  [LocationPolicyType.SubscriberAppointmentAutomaticApproval]: 'true',
  [LocationPolicyType.SubscriptionAutomaticApproval]: 'true',
};

const LocationPolicyTypeDisplayNameMap: Record<LocationPolicyType, string> = {
  [LocationPolicyType.SubscriberAppointmentAutomaticApproval]: 'Auto-Approve Appointment Requests',
  [LocationPolicyType.SubscriptionAutomaticApproval]: 'Auto-Approve New Members',
};

const LocationPolicyTypeDescriptionMap: Record<LocationPolicyType, string> = {
  [LocationPolicyType.SubscriberAppointmentAutomaticApproval]: 'Control whether appointments requested to this location should be automatically approved. If false, a location admin will be required to manually approve/deny each requested appointment.',
  [LocationPolicyType.SubscriptionAutomaticApproval]: 'Control whether new members can join this location without you manually approving them.',
};

const LocationPolicyTypeSelect: React.FC<LocationPolicyTypeSelectProps> = ({
  name,
  filter = () => true,
}) => {
  const fieldFn =
    (
      {
        form,
        field,
        meta,
      }: FieldProps<CreateLocationPolicyInput['policyType']>
    ) => {
      return (
        <>
          <Select
            onChange={e => {
              const val = e.target.value as LocationPolicyType;
              form.setFieldValue(name, val);

              const valType = LocationPolicyPolicyTypeValueTypeMap[val];
              form.setFieldValue(
                LocationPolicyModFields[LocationPolicyModFieldKey.ValueType],
                valType,
              );

              const defaultVal = LocationPolicyPolicyTypeDefaultValueMap[val];
              form.setFieldValue(
                LocationPolicyModFields[LocationPolicyModFieldKey.Value],
                defaultVal,
              );

              form.setFieldValue(
                LocationPolicyModFields[LocationPolicyModFieldKey.Name],
                val,
              );
            }}
          >
            {Boolean(field.value) || <option key="empty" value=""></option>}
            {Object.values(LocationPolicyType).map(
              v => filter(v) && (
                <option key={v} value={v}>
                  {LocationPolicyTypeDisplayNameMap[v]}
                </option>
              )
            )}
          </Select>
          <Text as="small">
            {LocationPolicyTypeDescriptionMap[field.value]}
          </Text>
          {meta.error && (
            <Text as="small" variant="error">
              {meta.error}
            </Text>
          )}
        </>
      );
    };

  return (
    <Label>
      <Text as="strong">
        Policy
      </Text>
      <Field
        name={name}
      >
        {fieldFn}
      </Field>
    </Label>
  );
};

export default LocationPolicyTypeSelect;
