import React from 'react';
import { Field, FieldProps } from 'formik';
import { Input, Label, Select, Text } from 'theme-ui';
import {
  CreateLocationPolicyInput,
  LocationPolicy,
  LocationPolicyValueType,
  UpdateLocationPolicyInput,
} from 'Generated/graphql';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { ManageLocationPoliciesLocationPolicies } from '..';
import LocationPolicyTypeField from './field/LocationPolicyTypeSelect';

dayjs.extend(utc);

export enum LocationPolicyModFieldKey {
  Uid = '0',
  ExternalUid = '1',
  ValueType = '2',
  PolicyType = '3',
  Value = '4',
  Name = '5',
}

type ModVal = keyof CreateLocationPolicyInput |
  keyof UpdateLocationPolicyInput

export const LocationPolicyModFields: Record<
  LocationPolicyModFieldKey,
  ModVal
> = {
  [LocationPolicyModFieldKey.Uid]: 'uid',
  [LocationPolicyModFieldKey.ExternalUid]: 'externalUid',
  [LocationPolicyModFieldKey.PolicyType]: 'policyType',
  [LocationPolicyModFieldKey.Name]: 'name',
  [LocationPolicyModFieldKey.ValueType]: 'valueType',
  [LocationPolicyModFieldKey.Value]: 'value',
};

const getUidField = (
  ff: ModVal,
): React.ReactNode => {
  return (
    <Field
      name={ff}
      type="hidden"
      key={ff}
    />
  );
};

const getPolicyTypeField = (
  ff: ModVal,
  existingLocationPolicies: ManageLocationPoliciesLocationPolicies,
): React.ReactNode => {
  return (
    <LocationPolicyTypeField
      name={ff}
      filter={v => !existingLocationPolicies.some(e => e.policyType === v)}
    />
  );
};

const getNameField = (
  ff: ModVal,
): React.ReactNode => {
  const fieldFn =
    (
      {
        form, field, meta: { error }
      }: FieldProps<CreateLocationPolicyInput['name']>
    ) => {
      return (
        <>
          <Input
            onChange={v => form.setFieldValue(
              LocationPolicyModFields[LocationPolicyModFieldKey.Name],
              v.target.value,
            )}
            value={field.value}
          />
          {error && (
            <Text as="small" variant="error">
              {error}
            </Text>
          )}
        </>
      );
    };

  return (
    <Label>
      <Text as="strong">
        Name
      </Text>
      <Field
        name={ff}
        key={ff}
      >
        {fieldFn}
      </Field>
    </Label>
  );
};

const getValueField = (
  ff: ModVal,
  existingMod?: LocationPolicy,
): React.ReactNode => {
  const fieldFn =
    (
      { form, meta: { error } }: FieldProps<CreateLocationPolicyInput['value']>,
    ) => {
      const valType = form.values.valueType === undefined ?
        existingMod?.valueType :
        form.values.valueType;
      switch (valType) {
      case LocationPolicyValueType.Boolean: {
        return (
          <>
            <Text as="strong">
              Value
            </Text>
            <Select
              defaultValue={existingMod?.value}
              onChange={e => {
                const val = e.target.value;
                if (val === 'true') {
                  form.setFieldValue(ff, 'true');
                } else {
                  form.setFieldValue(ff, 'false');
                }
              }}
            >
              <option value="true">
                True
              </option>
              <option value="false">
                False
              </option>
            </Select>
            {error && (
              <Text as="small" variant="error">
                {error}
              </Text>
            )}
          </>
        );
      }
      default:
        return null;
      }
    };

  return (
    <Field
      name={ff}
      key={ff}
    >
      {fieldFn}
    </Field>
  );
};

const getCreateFormFields = (
  existingLocationPolicies: ManageLocationPoliciesLocationPolicies,
) => {
  const allItems = Object.entries({
    [LocationPolicyModFieldKey.PolicyType]: 'policyType',
    [LocationPolicyModFieldKey.ExternalUid]: 'externalUid',
  }).map(
    ([k, f]) => {
      const ffk = k as LocationPolicyModFieldKey;
      const ff = f as ModVal;
      switch (ffk) {
      case LocationPolicyModFieldKey.PolicyType:
        return getPolicyTypeField(ff, existingLocationPolicies);
      }
    });

  return allItems;
};

const getUpdateFormFields = (
  hideFields: LocationPolicyModFieldKey[] | undefined,
) => {
  const allItems = Object.entries({
    [LocationPolicyModFieldKey.Uid]: 'uid',
  }).map(
    ([k, f]) => {
      const ffk = k as LocationPolicyModFieldKey;
      const ff = f as ModVal;
      if (!hideFields || !hideFields.includes(ffk)) {
        switch (ffk) {
        case LocationPolicyModFieldKey.Uid:
          return getUidField(ff);
        }
      }
    });

  return allItems;
};

export type OverrideLocationPolicyFormFields = Partial<
  Record<LocationPolicyModFieldKey, React.ReactNode | null>
>

const getFormFields = (
  hideFields: LocationPolicyModFieldKey[] | undefined,
  existingMod?: LocationPolicy,
) => {
  const allItems = Object.entries(LocationPolicyModFields).map(
    ([k, ff]) => {
      const ffk = k as LocationPolicyModFieldKey;
      const fc = (() => {
        if (!hideFields || !hideFields.includes(ffk)) {
          switch (ffk) {
          case LocationPolicyModFieldKey.Value:
            return getValueField(ff, existingMod);
          case LocationPolicyModFieldKey.Name:
            return getNameField(ff);
          }
        }
      })();

      return fc;
    });

  return allItems;
};

export const getLocationPolicyFormFields = (
  hideFields: LocationPolicyModFieldKey[] | undefined,
  existingMod?: LocationPolicy,
) => getFormFields(hideFields, existingMod);

export const getUpdateLocationPolicyFormFields =
  (
    hideFields: LocationPolicyModFieldKey[] | undefined,
  ) => getUpdateFormFields(hideFields);

export const getCreateLocationPolicyFormFields =
  (
    existingLocationPolicies: ManageLocationPoliciesLocationPolicies,
  ) => getCreateFormFields(existingLocationPolicies);
