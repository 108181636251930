import React from 'react';
import { Modal } from 'antd';
import { Text } from 'theme-ui';
import { useV2Gateway_DeleteLocationMutation } from 'Generated/graphql';

type DeleteLocationModalProps = {
  deleteLocationUid: string | undefined;
  onClose: () => void;
  onDeleted: () => void;
};

const DeleteLocationModal: React.FC<
  DeleteLocationModalProps
> = ({
  deleteLocationUid,
  onClose,
  onDeleted,
}) => {
  const [
    deleteLocation,
    { loading },
  ] = useV2Gateway_DeleteLocationMutation();

  const handleOk = () => {
    if (deleteLocationUid) {
      deleteLocation({
        variables: {
          locationUid: deleteLocationUid,
        }
      }).then(() => {
        onDeleted();
      });
    }
  };

  return (
    (<Modal
      title="Delete Location"
      open={Boolean(deleteLocationUid)}
      onOk={handleOk}
      okButtonProps={{
        loading: loading,
        disabled: loading,
      }}
      onCancel={onClose}
    >
      <Text>
        The location cannot be recovered once removed, but you can always
        recreate it.
      </Text>
    </Modal>)
  );
};

export default DeleteLocationModal;

