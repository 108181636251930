type ModUid = {
  uid: string;
}

export const someModUid = <T extends ModUid>(
  m: T,
  mods: T[],
): boolean => {
  if (mods.length === 0) {
    return false;
  }

  const head = mods[0];
  const tail = mods.slice(1);

  if (head.uid === m.uid) {
    return true;
  }

  return someModUid(m, tail);
};

export const getDeDupedByUid = <
  M extends ModUid = ModUid
>(l: M[]): M[] => {
  if (l.length === 0) {
    return [];
  }

  const head = l[0];
  const tail = l.slice(1);
  const chunk = getDeDupedByUid(tail);

  const alreadyIn = someModUid(head, chunk);

  if (alreadyIn) {
    return chunk;
  }

  return chunk.concat(head);
};

export const someModField = <T extends Record<string, unknown>>(
  field: keyof T,
) => (
    m: T,
    mods: T[],
  ): boolean => {
    if (mods.length === 0) {
      return false;
    }

    const head = mods[0];
    const tail = mods.slice(1);

    if (head[field] === m[field]) {
      return true;
    }

    return someModField(field)(m, tail);
  };


export const getDeDupedByField = <
  M extends Record<string, unknown> = Record<string, unknown>
>(
    field: keyof M,
  ) => (
    l: M[],
  ): M[] => {
    if (l.length === 0) {
      return [];
    }

    const head = l[0];
    const tail = l.slice(1);
    const chunk = getDeDupedByField(field)(tail);

    const alreadyIn = someModField(field)(head, chunk);

    if (alreadyIn) {
      return chunk;
    }

    return chunk.concat(head);
  };

