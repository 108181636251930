import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import FileForm, { FileFormMethods, FileFormProps } from './Form';
import {
  useV2Gateway_DeleteFilesMutation,
  useV2Gateway_GetFilesLazyQuery,
  V2Gateway_GetFilesLazyQueryHookResult,
} from 'Generated/graphql';
import {
  FormLinkLabel,
} from 'Common/context/FormChainContextProvider';

type Files = NonNullable<
  V2Gateway_GetFilesLazyQueryHookResult[1]['data']
>['getFilesByExternalUid']
type File = Files[number]

type ManageFileProps = {
  inline?: boolean;
  files?: Files;
  externalUid?: string;
  onChange?: () => void;
  formProps?: Partial<FileFormProps>;
  onMethods: (
    methods: FileFormMethods,
  ) => void;
};

export const FileFormChainLinkLabel: FormLinkLabel = 'file';

const ManageFiles: React.FC<ManageFileProps> = ({
  inline,
  files: filesProp,
  externalUid: externalUidProp,
  formProps,
  onChange,
  onMethods,
}) => {
  const [showModal, setShowModal] = useState(true);
  const [files, setFiles] = useState<Files | undefined>();
  const [getFiles, getFilesQuery] = useV2Gateway_GetFilesLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [focusedFile, setFocusedFile] = useState<File | undefined>();
  const [deleteFiles, deleteFileQuery] = useV2Gateway_DeleteFilesMutation();

  const confirmDelete = (ctr: File) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${ctr.label}?`,
      onOk: () => deleteFiles({
        variables: {
          internalUids: [ctr.uid],
        }
      })
    });
  };

  const fetchMods = () => {
    if (!filesProp) {
      if (externalUidProp) {
        getFiles({
          variables: {
            externalUids: [externalUidProp],
          }
        });
      }
    }
  };

  const onSuccess = () => {
    fetchMods();
  };

  useEffect(() => {
    fetchMods();
  }, [filesProp]);

  useEffect(() => {
    const { data } = getFilesQuery;
    if (data?.getFilesByExternalUid) {
      setFiles(data.getFilesByExternalUid);
    }
  }, [getFilesQuery.data]);

  useEffect(() => {
    if (focusedFile) {
      setShowModal(true);
    }
  }, [focusedFile]);

  useEffect(() => {
    const { data } = deleteFileQuery;
    if (data?.deleteFiles) {
      onSuccess();
      onChange && onChange();
    }
  }, [deleteFileQuery.data]);

  useEffect(() => {
    if (filesProp) {
      setFiles(filesProp);
    }
  }, [filesProp]);

  const getForm = () => (
    <FileForm
      onMethods={onMethods}
      existingMods={files}
      onClickDelete={(ctr) => confirmDelete(ctr)}
      {...formProps}
    />
  );

  return (<>
    {showModal && (inline ? (
      <>
        {getForm()}
      </>
    ) : (
      <Modal
        title="Files"
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          setFocusedFile(undefined);
        }}
        onOk={() => {
          setShowModal(false);
        }}
      >
        {getForm()}
      </Modal>
    ))}
  </>);
};

export default ManageFiles;
