import React from 'react';
import { List, Divider } from 'antd';
import { Text } from 'theme-ui';
import LocationListItem from
  'Common/components/ManageLocations/LocationListItem';
import { AccountType } from 'Common/functions/AccountType';
import { V2Gateway_GetLocationsQueryHookResult } from 'Generated/graphql';

type ManageLocationsLocations = NonNullable<
  V2Gateway_GetLocationsQueryHookResult['data']
>['getLocations']

export type ManageLocationsLocation = ManageLocationsLocations[number]

type PresentLocationListProps = {
  locations: ManageLocationsLocation[];
  accountType: AccountType;
  editingLocationUid: string | undefined;
  setEditingLocationUid: (uid: string | undefined) => void;
  setDeleteLocationUid: (uid: string | undefined) => void;
  isCreatingLocation: boolean;
  onUpdate?: () => void;
};

const PresentLocationList: React.FC<PresentLocationListProps> = ({
  locations,
  accountType,
  editingLocationUid,
  setEditingLocationUid,
  setDeleteLocationUid,
  isCreatingLocation,
  onUpdate,
}) => {
  return (
    <List
      dataSource={locations}
      renderItem={location => (
        <>
          <LocationListItem
            key={location.uid}
            onUpdate={() => {
              onUpdate && onUpdate();
              setEditingLocationUid(undefined);
            }}
            accountType={accountType}
            location={location}
            manageLocationProps={{
              onClickDelete: () => setDeleteLocationUid(location.uid),
              index: 0,
              onClickEdit: () => setEditingLocationUid(location.uid),
              onClickClose: () => setEditingLocationUid(undefined),
              isEditing: location.uid === editingLocationUid,
              hideEditButton: isCreatingLocation,
            }}
          />
          <Divider />
        </>
      )}
      locale={{
        emptyText: (
          <Text variant="body2" as="p">
            Create a location to begin scheduling service providers!
          </Text>
        ),
      }}
    />
  );
};

export default PresentLocationList;

