import {
  useV2Gateway_GetUsersLazyQuery,
  V2Gateway_GetUsersQueryHookResult,
} from 'Generated/graphql';
import { useEffect, useState } from 'react';

export type UseUsers = NonNullable<
  V2Gateway_GetUsersQueryHookResult['data']
>['getUsers']

const useUsers = (ownerUids?: string[]): UseUsers | undefined => {
  const [getUsers, getUsersQuery] = useV2Gateway_GetUsersLazyQuery();
  const [users, setUsers] = useState<UseUsers>();

  useEffect(() => {
    if (ownerUids?.length) {
      getUsers({
        variables: {
          uids: ownerUids,
        }
      });
    }
  }, [ownerUids]);

  useEffect(() => {
    const { data } = getUsersQuery;
    if (data?.getUsers) {
      setUsers(data.getUsers);
    }
  }, [getUsersQuery.data]);

  return users;
};

export default useUsers;

