import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { UseUsers } from 'Common/hooks/useUsers';
import {
  V2Gateway_QueryLocationSubscriptionRequestsQueryHookResult,
  V2Gateway_QueryLocationSubscriptionsQueryHookResult,
  LocationSubscriptionRequestStatus,
  useV2Gateway_UpdateLocationSubscriptionRequestsMutation,
  useV2Gateway_DeleteLocationSubscriptionMutation,
  UpdateLocationSubscriptionRequestInput,
} from 'Generated/graphql';
import { useEffect } from 'react';
import { Button, Flex, Text } from 'theme-ui';

type LocationSubscriptionRequest = NonNullable<
  V2Gateway_QueryLocationSubscriptionRequestsQueryHookResult['data']
>['queryLocationSubscriptionRequests']['locationSubscriptionRequests'][number]

type LocationSubscription = NonNullable<
  V2Gateway_QueryLocationSubscriptionsQueryHookResult['data']
>['queryLocationSubscriptions'][number]

type LocationSubscriberListItemProps = {
  user: UseUsers[number];
  locationSubscriptionRequest?: LocationSubscriptionRequest;
  locationSubscription?: LocationSubscription;
  onUpdate?: () => void;
}

const LocationSubscriberListItem: React.FC<LocationSubscriberListItemProps> = ({
  user,
  locationSubscriptionRequest,
  locationSubscription,
  onUpdate,
}) => {
  const [
    updateLocationSubscriptionRequests,
    updateLocationSubscriptionRequestsQuery,
  ] = useV2Gateway_UpdateLocationSubscriptionRequestsMutation();
  const [
    deleteLocationSubscription,
    deleteLocationSubscriptionQuery,
  ] = useV2Gateway_DeleteLocationSubscriptionMutation();

  const updateLocationSubscriptionRequest = (
    l: LocationSubscriptionRequest,
    status: LocationSubscriptionRequestStatus,
  ) => {
    const lsr: UpdateLocationSubscriptionRequestInput = {
      locationSubscription: {
        locationUid: l.locationSubscription.locationUid,
      },
      uid: l.uid,
      status,
    };
    return updateLocationSubscriptionRequests({
      variables: {
        data: [lsr],
      }
    });
  };

  const doDeleteLocationSubscription = (
    l: LocationSubscription,
  ) => deleteLocationSubscription({
    variables: {
      locationUid: l.locationUid,
      ownerUid: l.ownerUid,
    }
  });

  useEffect(() => {
    const { data } = updateLocationSubscriptionRequestsQuery;
    if (data?.updateLocationSubscriptionRequests) {
      onUpdate && onUpdate();
    }
  }, [updateLocationSubscriptionRequestsQuery.data]);

  useEffect(() => {
    const { data } = deleteLocationSubscriptionQuery;
    if (data?.deleteLocationSubscription) {
      onUpdate && onUpdate();
    }
  }, [deleteLocationSubscriptionQuery.data]);

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
      }}
    >
      <Text as="strong">
        {user.fullName}
      </Text>
      {locationSubscriptionRequest ? (
        <Flex>
          <Button
            disabled={updateLocationSubscriptionRequestsQuery.loading}
            onClick={() => updateLocationSubscriptionRequest(
              locationSubscriptionRequest as LocationSubscriptionRequest,
              LocationSubscriptionRequestStatus.Approved,
            ).then(() => message.info('Subscription Approved'))}
          >
            <CheckOutlined />
          </Button>
          <Button
            disabled={updateLocationSubscriptionRequestsQuery.loading}
            onClick={() => updateLocationSubscriptionRequest(
              locationSubscriptionRequest as LocationSubscriptionRequest,
              LocationSubscriptionRequestStatus.Denied,
            ).then(() => message.info('Subscription Denied'))}
          >
            <CloseOutlined />
          </Button>
        </Flex>
      ) : (
        <Button
          disabled={deleteLocationSubscriptionQuery.loading}
          onClick={() => doDeleteLocationSubscription(
            locationSubscription as LocationSubscription
          ).then(() => message.info('Subscription Removed'))}
        >
          Remove
        </Button>
      )}
    </Flex>
  );
};

export default LocationSubscriberListItem;

