import { ThemeUIStyleObject } from 'theme-ui';

const styles: Record<string, ThemeUIStyleObject> = {
  button: {
    minWidth: '20%',
    my: '0.5rem'
  }
};

export default styles;
