import { ThemeUIStyleObject } from 'theme-ui';

const styles: Record<string, ThemeUIStyleObject> = {
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default styles;
