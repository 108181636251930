
import { Modal, Select } from 'antd';
import IdentityContext from 'Common/context/IdentityContext';
import UserContext from 'Common/context/UserContext';
import { useV2Gateway_SetUserCountryMutation } from 'Generated/graphql';
import React, { useContext, useEffect, useState } from 'react';

type CountrySelectModalProps = {
  onSubmit?: () => void;
  visible: boolean;
  masqueradeAsUid?: string;
}

const { Option } = Select;

const CountrySelectModal: React.FC<CountrySelectModalProps> = ({
  onSubmit,
  visible,
  masqueradeAsUid,
}) => {
  const {
    country: ctxCountry,
    getIdentityQuery,
  } = useContext(IdentityContext);
  const [setUserCountry, setUserCountryQuery] = useV2Gateway_SetUserCountryMutation();
  const [country, setCountry] = useState('CA');

  useEffect(() => {
    const { data } = setUserCountryQuery;
    if (data?.setUserCountry) {
      getIdentityQuery.refetch();
      onSubmit && onSubmit();
    }
  }, [setUserCountryQuery.data]);

  return (
    (<Modal
      title="Please select your country"
      open={visible}
      onOk={() => {
        country && setUserCountry({
          variables: {
            country,
            masqueradeAsUid,
          }
        });
      }}
    >
      <div
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Select
          sx={{
            minWidth: '60%'
          }}
          onChange={(v: string|null) => {
            v && setCountry(v);
          }}
          defaultValue={ctxCountry === 'unknown' || ctxCountry === null ? null : ctxCountry}
        >
          <Option value="CA">Canada</Option>
          <Option value="US">United States</Option>
        </Select>
      </div>
    </Modal>)
  );
};

export default CountrySelectModal;
