import {
  CreateFileInput,
  Maybe,
  UpdateFileInput,
  V2Gateway_CreateFilesMutationHookResult,
  V2Gateway_GetFilesQueryHookResult,
  V2Gateway_UpdateFilesMutationHookResult,
} from 'Generated/graphql';
import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { FileFormValues } from '.';
import { deleteTypeNameField } from 'Common/functions/Form';

type File = NonNullable<
  V2Gateway_GetFilesQueryHookResult['data']
>['getFilesByExternalUid'][number]

export const getFileFormCreateFn = (
  doCreate: V2Gateway_CreateFilesMutationHookResult[0],
) => async (data: CreateFileInput): Promise<{
      uid: string,
    } | null> => {
  const res = await doCreate({
    variables: {
      data,
    }
  });

  const rUid = res.data?.createFiles[0];
  if (rUid) {
    return {
      uid: rUid,
    };
  }

  return null;
};

export const getFileFormUpdateFn = (
  doUpdate: V2Gateway_UpdateFilesMutationHookResult[0],
) => async (data: UpdateFileInput): Promise<{
      uid: string,
    } | null> => {
  const res = await doUpdate({
    variables: {
      data,
    }
  });

  if (res.data?.updateFiles) {
    return {
      uid: data.uid
    };
  }

  return null;
};

const commonFileFormValidationSchema = {
  index: yup.array(yup.number()),
  label: yup.array(yup.string().required()),
  uploadedFile: yup.object({
    publicUrl: yup.array(yup.string().required()),
    objectName: yup.array(yup.string().required()),
  }),
};

export const createFileFormValidationSchema = yup.object(
  commonFileFormValidationSchema,
);

export const updateFileFormValidationSchema = yup.object({
  uid: yup.string(),
}).shape(commonFileFormValidationSchema);

export const getInitialValueFromFocusedMod = (
  focusedMod: File[],
): FileFormValues => {
  const b = focusedMod.map(m => deleteTypeNameField<File>(m));

  return {
    label: b.map(b => b.label),
    uid: b.map(b => b.uid),
    index: b.map(b => b.index),
    uploadedFile: {
      publicUrl: b.map(b => b.uploadedFile.publicUrl),
      objectName: b.map(b => b.uploadedFile.objectName),
    }
  };
};

export const getFileFormInitialValue = (
  focusedMod: Maybe<File[]>,
): Partial<FileFormValues> => {
  if (focusedMod?.length) {
    return getInitialValueFromFocusedMod(focusedMod);
  }

  return {};
};

export const getFileFormFormikConfig = (
  focusedMod: Maybe<File[]>,
) => {
  const initialValues =
    getFileFormInitialValue(focusedMod);
  const formikCfg: FormikConfig<
    Partial<FileFormValues>
  > = {
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      throw new Error('getFileFormFormikConfig onSubmit Unimplemented');
    },
    validationSchema: focusedMod?.length ?
      updateFileFormValidationSchema :
      createFileFormValidationSchema,
  };

  return formikCfg;
};

