import { FetchPolicy } from '@apollo/client';
import {
  V1Beta3_ReferenceMatch,
  useV2Gateway_QueryLocationSubscriptionRequestsLazyQuery,
  V2Gateway_QueryLocationSubscriptionRequestsQueryHookResult,
  LocationSubscriptionRequestStatus,
} from 'Generated/graphql';
import { useEffect, useState } from 'react';

export type UseLocationSubscriptionRequests = NonNullable<
  V2Gateway_QueryLocationSubscriptionRequestsQueryHookResult['data']
>['queryLocationSubscriptionRequests']['locationSubscriptionRequests']

const useLocationSubscriptionRequests = (
  references?: V1Beta3_ReferenceMatch[],
  fetchPolicy?: FetchPolicy,
): [UseLocationSubscriptionRequests | undefined, () => void] => {
  const [
    queryLocationSubscriptionRequests,
    queryLocationSubscriptionRequestsQuery,
  ] = useV2Gateway_QueryLocationSubscriptionRequestsLazyQuery();
  const [locationSubscriptionRequets, setLocationSubscriptionRequests] =
    useState<UseLocationSubscriptionRequests>();

  useEffect(() => {
    if (references) {
      queryLocationSubscriptionRequests({
        variables: {
          query: {
            page: 0,
            pageSize: 50,
            references,
            status: LocationSubscriptionRequestStatus.Pending,
          },
        },
        fetchPolicy
      });
    }
  }, [JSON.stringify(references)]);

  useEffect(() => {
    const { data } = queryLocationSubscriptionRequestsQuery;
    if (data?.queryLocationSubscriptionRequests) {
      const lsrs = data.queryLocationSubscriptionRequests
        .locationSubscriptionRequests;
      setLocationSubscriptionRequests(lsrs);
    }
  }, [queryLocationSubscriptionRequestsQuery.data]);

  return [
    locationSubscriptionRequets,
    () => queryLocationSubscriptionRequestsQuery.refetch && references &&
      queryLocationSubscriptionRequestsQuery.refetch(),
  ];
};

export default useLocationSubscriptionRequests;

