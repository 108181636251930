import Bugsnag from '@bugsnag/js';
import { useEffect, useState } from 'react';
import { getScriptElement } from 'Common/functions/Script';

const isTest = process.env.NODE_ENV === 'test';

const useScript = (src: string): boolean => {
  const [loaded, setLoaded] = useState(isTest);

  useEffect(() => {
    const dupeScript = document.querySelector(`script[src="${src}"]`);
    if (!dupeScript) {
      getScriptElement(0, src, () => setLoaded(true), s => {
        if (s) {
          document.body.appendChild(s);
        } else {
          const msg = 'failed to load script: ' + src;
          console.error(msg);
          Bugsnag.notify(msg);
        }
      });
    } else {
      setLoaded(true);
    }
  }, [src]);

  return loaded;
};

export default useScript;
