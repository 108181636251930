import React, { useContext, useEffect, useState } from 'react';
import styles from './style';
import { PlusOutlined } from '@ant-design/icons';
import { List, Modal, Skeleton } from 'antd';
import ServiceAreaForm, { ServiceAreaFormMethods } from './Form';
import {
  useV2Gateway_DeleteServiceAreasMutation,
  V2Gateway_GetServiceAreasLazyQueryHookResult,
  useV2Gateway_GetServiceAreasForLocationsLazyQuery,
} from 'Generated/graphql';
import ServiceAreaListItem from './ListItem';
import { Button, Spinner, Text } from 'theme-ui';
import FilesContext from 'Common/context/FilesContext';
import ManageFiles from 'Common/components/ManageFiles';
import {
  FormLinkLabel,
} from 'Common/context/FormChainContextProvider';
import { FileFormMethods } from '../ManageFiles/Form';

type ServiceAreas = NonNullable<
  V2Gateway_GetServiceAreasLazyQueryHookResult[1]['data']
>['getServiceAreas']
type ServiceArea = ServiceAreas[number]

type ManageServiceAreaProps = {
  serviceAreas?: ServiceAreas;
  externalUid?: string;
  onServiceAreaMethods: (
    methods: ServiceAreaFormMethods,
  ) => void;
  onFileMethods: (
    methods: FileFormMethods,
  ) => void;
  showForm: boolean;
  onClickSubmit: () => Promise<void>;
  isLoading: boolean;
  onClickCreateServiceArea: () => void;
};

export const ServiceAreasFormChainLinkLabel: FormLinkLabel = 'service-area';

const ManageServiceAreas: React.FC<ManageServiceAreaProps> = ({
  serviceAreas: serviceAreasProp,
  externalUid,
  onServiceAreaMethods,
  onFileMethods,
  showForm,
  onClickSubmit,
  isLoading,
  onClickCreateServiceArea,
}) => {
  const [serviceAreas, setServiceAreas] = useState<
    ServiceAreas | undefined
  >(serviceAreasProp);
  const [
    getServiceAreas,
    getServiceAreasQuery,
  ] = useV2Gateway_GetServiceAreasForLocationsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [
    focusedServiceArea,
    setFocusedServiceArea,
  ] = useState<ServiceArea | undefined>();
  const [
    deleteServiceAreas,
    deleteServiceAreaQuery,
  ] = useV2Gateway_DeleteServiceAreasMutation();
  const {
    files,
    fetchMods: fetchFiles,
  } = useContext(FilesContext);

  const confirmDelete = (ctr: ServiceArea) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${ctr.name}?`,
      onOk: () => deleteServiceAreas({
        variables: {
          internalUids: [ctr.uid],
        }
      })
    });
  };

  const fetchMods = () => {
    if (!serviceAreasProp) {
      if (externalUid) {
        getServiceAreas({
          variables: {
            locationUids: [externalUid],
          }
        });
      }
    }
  };

  const onSuccess = () => {
    fetchMods();
    setFocusedServiceArea(undefined);
    fetchFiles && fetchFiles();
  };

  useEffect(() => {
    if (serviceAreasProp) {
      setServiceAreas(serviceAreasProp);
    } else {
      fetchMods();
    }
  }, [serviceAreasProp]);

  useEffect(() => {
    const { data } = getServiceAreasQuery;
    if (data?.getServiceAreasByLocationUid) {
      setServiceAreas(data.getServiceAreasByLocationUid);
    }
  }, [getServiceAreasQuery.data]);

  useEffect(() => {
    if (deleteServiceAreaQuery.data?.deleteServiceAreas) {
      fetchMods();
    }
  }, [deleteServiceAreaQuery.data]);

  useEffect(() => {
    setFocusedServiceArea(undefined);
  }, [externalUid]);

  const getList = () => serviceAreas?.length ? (
    <>
      <List
        dataSource={serviceAreas}
        renderItem={(ctr, i) => (
          <ServiceAreaListItem
            key={i}
            mod={ctr}
            onClickEdit={() => setFocusedServiceArea(ctr)}
            onClickDelete={() => confirmDelete(ctr)}
          />
        )}
      />
    </>
  ) : getServiceAreasQuery.loading ? (
    <Skeleton />
  ) : (
    <div sx={styles.noContent}>
      <Text>
        Create a service area to specify exact areas within your{' '}location
        so that all parties know where the service/event will be take place.
      </Text>
    </div>
  );

  const getForm = () => {
    const filesForArea = focusedServiceArea && files ? files.filter(
      f => f.externalUid === focusedServiceArea.uid
    ) : undefined;

    return <>
      <ServiceAreaForm
        existingServiceArea={focusedServiceArea}
        onMethods={onServiceAreaMethods}
      />
      <ManageFiles
        files={filesForArea}
        inline
        onMethods={onFileMethods}
      />
      {showForm || focusedServiceArea ? (
        <Button
          onClick={() => onClickSubmit().then(() => onSuccess())}
          sx={{ width: '70%', }}
        >
          {isLoading ? (
            <Spinner size={32} />
          ) : 'Save'}
        </Button>
      ) : (
        <Button
          variant="buttons.secondary"
          onClick={() => onClickCreateServiceArea()}
        >
          <PlusOutlined />
          Create Service Area
        </Button>
      )}
    </>;
  };

  const getContent = () => {
    return (
      <>
        {showForm || focusedServiceArea ? getForm() : getList()}
      </>
    );
  };

  return getContent();
};

export default ManageServiceAreas;
