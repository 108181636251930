import { Modal } from 'antd';
import IdentityContext from 'Common/context/IdentityContext';
import { getCookie, AppCookie, setCookie } from 'Common/functions/Cookie';
import useLocationSubscriptionRequests from
  'Common/hooks/useLocationSubscriptionRequests';
import {
  LocationType,
  ReferenceType,
  useV2Gateway_GetLocationLazyQuery,
  V1Beta3_ReferenceMatchType,
  V2Gateway_QueryLocationsQueryHookResult,
} from 'Generated/graphql';
import { useContext, useEffect, useState } from 'react';
import { Heading, Flex } from 'theme-ui';
import LocationForm from '../LocationForm';
import { LocationModFieldKey } from '../LocationForm/functions';

type locations = NonNullable<
  V2Gateway_QueryLocationsQueryHookResult['data']
>['queryLocations']['locations']

type PromptCreateLocation = {
  ownerUid: string | undefined;
  onFlowComplete?: (location: Omit<locations[number], 'serviceAreas'>) => void;
  preventClose?: boolean;
  isOpen: boolean;
  onClose: () => void;
  isAnonymous?: boolean;
}

const isSSR = typeof window === 'undefined';

const getFieldOverrides = (
  locationType: LocationType,
  fullName: string,
  isCustomer: boolean,
  isAnonymous: boolean | undefined,
) => {
  if (isAnonymous) {
    return {
      name: 'Temporary Location',
      type: LocationType.IndividualIndeterminate,
      phoneNumber: '0000000000',
    };
  }

  if (isCustomer) {
    const isIndividualHome = locationType === LocationType.Individual;
    const name = isIndividualHome ? `${fullName}'s Home` : `${fullName}'s Work`;
    return {
      name,
      type: locationType || LocationType.Individual,
    };
  }

  return undefined;
};

const PromptCreateLocation: React.FC<
  PromptCreateLocation
> = ({
  ownerUid,
  onFlowComplete,
  preventClose = false,
  isOpen,
  onClose,
  isAnonymous,
}) => {
  const {
    fullName,
  } = useContext(IdentityContext);
  const timesClosedStr = isSSR ?
    null :
    getCookie(AppCookie.ClosedJoinSharedLocationPrompt);
  const timesClosed = timesClosedStr ? parseInt(timesClosedStr) : 0;
  const [
    isLocationSubscriptionsDownloaded,
    setIsLocationSubscriptionsDownloaded,
  ] = useState(false);
  const [locationSubscriptionRequests] =
      useLocationSubscriptionRequests(ownerUid ? [{
        ids: [ownerUid],
        type: ReferenceType.Owner,
        match_type: V1Beta3_ReferenceMatchType.Any,
      }] : undefined);
  const [getLocation] = useV2Gateway_GetLocationLazyQuery();

  const handleLocationCreated = (locationUid: string) => {
    getLocation({
      variables: {
        locationUid,
      }
    }).then(async ({ data }) => {
      if (data) {
        const loc = data.getLocation;
        if (onFlowComplete) {
          onFlowComplete(loc);
        }
      }
    });
  };

  useEffect(() => {
    if (locationSubscriptionRequests && !isLocationSubscriptionsDownloaded) {
      setIsLocationSubscriptionsDownloaded(true);
    }
  }, [locationSubscriptionRequests]);

  return (
    <Modal
      open={isOpen}
      title=""
      footer={<></>}
      closable={!preventClose}
      onCancel={() => {
        if (preventClose) {
          return;
        }

        const tc = timesClosed ? timesClosed + 1 : 1;
        setCookie(
          AppCookie.ClosedJoinSharedLocationPrompt,
          tc.toString(),
        );

        onClose();
      }}
    >
      <>
        {isAnonymous || (
          <Heading variant='h1'>
            Where would you like your service?
          </Heading>
        )}
        <Flex>
          <Flex
            sx={{
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <>
                <Heading variant='h1'>
                    Add Your Address:
                </Heading>
                <LocationForm
                  hideFields={[
                    LocationModFieldKey.Type,
                    LocationModFieldKey.Name,
                    LocationModFieldKey.PhoneNumber,
                  ]}
                  overrideFields={getFieldOverrides(
                    LocationType.Individual,
                    (fullName || 'Customer'),
                    true,
                    isAnonymous,
                  )}
                  onCreateLocations={uids => {
                    if (uids.length) {
                      handleLocationCreated(uids[0]);
                    }
                  }}
                  submitButtonCopy={isAnonymous ?
                    'Book Services In My Area' :
                    undefined}
                />
              </>
            </Flex>
          </Flex>
        </Flex>
      </>
    </Modal>
  );
};

export default PromptCreateLocation;

