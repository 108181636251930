import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  useV2Gateway_QueryLocationSubscriptionsLazyQuery,
  V1Beta3_ReferenceMatch,
  V2Gateway_QueryLocationSubscriptionsQueryHookResult,
} from 'Generated/graphql';
import { useEffect, useMemo } from 'react';

export type UseLocationSubscriptions = NonNullable<
  V2Gateway_QueryLocationSubscriptionsQueryHookResult['data']
>['queryLocationSubscriptions']

const useLocationSubscriptions = (
  referenceMatches?: V1Beta3_ReferenceMatch[],
  fetchPolicy?: WatchQueryFetchPolicy,
): [UseLocationSubscriptions | undefined, () => void] => {
  const [queryLocationSubscriptions, queryLocationSubscriptionsQuery] =
    useV2Gateway_QueryLocationSubscriptionsLazyQuery();

  useEffect(() => {
    if (referenceMatches) {
      queryLocationSubscriptions({
        variables: {
          query: {
            page: 0,
            pageSize: 50,
            referenceMatches,
          }
        },
        fetchPolicy,
      });
    }
  }, [JSON.stringify(referenceMatches)]);

  const locationSubscriptions = useMemo(() => {
    return queryLocationSubscriptionsQuery.data?.queryLocationSubscriptions;
  }, [queryLocationSubscriptionsQuery.data]);

  return [
    locationSubscriptions,
    () => queryLocationSubscriptionsQuery.refetch && referenceMatches &&
      queryLocationSubscriptionsQuery.refetch()
  ];
};

export default useLocationSubscriptions;

