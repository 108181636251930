import {
  default as ManageLocationPoliciesContext,
} from 'Common/context/ManageLocationPoliciesContext';
import { useContext } from 'react';
import { Divider, Flex } from 'theme-ui';
import {
  ManageLocationPoliciesLocationPolicy,
  ManageLocationPoliciesLocationPolicies,
} from '..';
import LocationPolicyListItem,
{ LocationPolicyListItemProps } from './ListItem';

type LocationPolicyListProps = {
  locationPolicies: ManageLocationPoliciesLocationPolicies,
  listItemProps?: Partial<Omit<LocationPolicyListItemProps, 'onClickEdit'>>,
}

const LocationPolicyList: React.FC<LocationPolicyListProps> = ({
  locationPolicies,
  listItemProps,
}) => {
  const {
    dispatch,
    focusedLocationPolicy,
  } = useContext(ManageLocationPoliciesContext);

  const getLocationPolicyListItem = (
    ar: ManageLocationPoliciesLocationPolicy,
  ): React.ReactNode => {
    return (
      <LocationPolicyListItem
        key={ar.uid}
        existingMod={ar}
        hideEditButton={Boolean(focusedLocationPolicy)}
        onClickEdit={() => dispatch({
          focusedLocationPolicy: ar,
        })}
        {...listItemProps}
      />
    );
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      {locationPolicies.map(
        (ar) => (
          <>
            {getLocationPolicyListItem(ar)}
            <Divider />
          </>
        )
      )}
    </Flex>
  );
};

export default LocationPolicyList;
