import React, { useContext } from 'react';
import { Button, Flex } from 'theme-ui';
import {
  V2Gateway_GetLocationsQueryHookResult,
  LocationType,
} from 'Generated/graphql';
import FilesContext from 'Common/context/FilesContext';
import {
  filterFilesForLocations,
  getFilesForm,
  getFilesPresentation,
} from './functions';
import IdentityContext from 'Common/context/IdentityContext';
import { AccountType } from 'Common/functions/AccountType';
import LocationForm, {
  LocationFormMethods,
} from 'Common/components/v2/LocationForm';
import { LocationModFieldKey } from 'Common/components/LocationForm/functions';
import { FileFormMethods } from 'Common/components/ManageFiles/Form';

export type ManageLocationsLocation = NonNullable<
  V2Gateway_GetLocationsQueryHookResult['data']
>['getLocations'][number]

export type ManageLocationProps = {
  location?: ManageLocationsLocation;
  onClickServiceAreas?: () => void;
  onClickLocationPolicies?: () => void;
  onClickLocationSubscribers?: () => void;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  onClickClose?: () => void;
  index?: number,
  isEditing?: boolean;
  hideEditButton?: boolean;
  accountType: AccountType;
  onLocationMethods: (
    methods: LocationFormMethods,
  ) => void;
  onFileMethods: (
    methods: FileFormMethods,
  ) => void;
}

const ManageLocation: React.FC<ManageLocationProps> = ({
  location,
  onClickServiceAreas,
  onClickLocationPolicies,
  onClickLocationSubscribers,
  index,
  isEditing,
  accountType,
  onLocationMethods,
  onFileMethods,
}) => {
  const {
    files: allFiles,
  } = useContext(FilesContext);
  const filesForLocation = location ? allFiles ?
    filterFilesForLocations(location, allFiles) :
    null : [];
  const {
    fullName,
  } = useContext(IdentityContext);
  const isCustomer = accountType === AccountType.Customer;
  const isServiceProvider = accountType === AccountType.ServiceProvider;
  const isBusinessAccount = accountType === AccountType.LocationOwner;

  return (
    <Flex
      sx={{
        flexBasis: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column-reverse',
      }}
    >
      {(!location || isEditing) ? (
        <Flex
          sx={{
            flexDirection: 'column',
            flexBasis: '75%',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <LocationForm
            onMethods={onLocationMethods}
            hideFields={isCustomer ? [
              LocationModFieldKey.Type,
              LocationModFieldKey.Name,
              LocationModFieldKey.PhoneNumber,
            ] : isServiceProvider ? [
              LocationModFieldKey.Type,
            ] : undefined}
            overrideFields={isCustomer && !location ? {
              name: `${fullName}'s Home`,
              type: LocationType.Individual,
            } : undefined}
            hideTypeOptions={isBusinessAccount ?
              [
                LocationType.Individual,
                LocationType.IndividualWork,
                LocationType.IndividualIndeterminate,
                LocationType.ServiceProvider,
              ] :
              undefined}
            existingLocation={location}
          >
            {isCustomer || filesForLocation && getFilesForm(
              filesForLocation,
              onFileMethods,
              index,
            )}
          </LocationForm>
        </Flex>
      ) : (
        <Flex
          sx={{
            flexBasis: '60%',
            justifyContent: 'space-between',
          }}
        >
          {filesForLocation && getFilesPresentation(
            filesForLocation,
          )}
        </Flex>
      )}
      <Flex sx={{
        justifyContent: 'flex-end',
        flexBasis: '25%',
      }}>
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {isBusinessAccount && Boolean(location) && (
            <>
              <Button
                onClick={
                  (): void =>
                    onClickLocationPolicies && onClickLocationPolicies()
                }
                variant="buttons.secondary"
                style={location &&
                  location.type === LocationType.ServiceProvider ?
                  { display: 'none' } :
                  {}}
              >
                Policies
              </Button>
              <Button
                onClick={
                  (): void =>
                    onClickLocationSubscribers && onClickLocationSubscribers()
                }
                variant="buttons.secondary"
                style={location &&
                  location.type === LocationType.ServiceProvider ?
                  { display: 'none' } :
                  {}}
              >
                Members
              </Button>
              <Button
                onClick={
                  (): void => onClickServiceAreas && onClickServiceAreas()
                }
                variant="buttons.secondary"
                style={location &&
                  location.type === LocationType.ServiceProvider ?
                  { display: 'none' } :
                  {}}
              >
                Service Areas
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ManageLocation;
