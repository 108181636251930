
import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { List } from 'antd';
import {
  V2Gateway_GetServiceAreasQueryHookResult,
} from 'Generated/graphql';
import { Button } from 'theme-ui';
import { formatServiceAreaSize } from '../functions';

type ServiceArea = NonNullable<V2Gateway_GetServiceAreasQueryHookResult['data']>['getServiceAreas'][number]

type ServiceAreaListItemProps = {
  mod: ServiceArea;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  disabled?: boolean;
}

const ServiceAreaListItem: React.FC<ServiceAreaListItemProps> = ({
  mod,
  onClickEdit,
  disabled,
  onClickDelete,
}) => {

  return mod ? (
    <List.Item actions={[
      <Button
        key="0"
        onClick={() => onClickEdit && onClickEdit()}
        disabled={disabled}
        variant="buttons.secondary"
      >
        <EditOutlined />
      </Button>,
      <Button
        key="1"
        onClick={() => onClickDelete && onClickDelete()}
        variant="buttons.danger"
        disabled={disabled}
      >
        <DeleteOutlined />
      </Button>
    ]}>
      <List.Item.Meta
        title={mod.name}
        description={`${formatServiceAreaSize(mod.size)} sized area`}
      />
    </List.Item>
  ) : <></>;
};

export default ServiceAreaListItem;
