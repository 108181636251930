import {
  ApolloClient,
} from '@apollo/client';
import {
  LocationType,
  V2Gateway_QueryLocationsDocument,
  V2Gateway_QueryLocationsQuery,
  V2Gateway_QueryLocationsQueryResult,
  V2Gateway_QueryLocationsQueryVariables,
} from 'Generated/graphql';

export type queryLocations =
  NonNullable<
    V2Gateway_QueryLocationsQueryResult['data']
  >['queryLocations']

const getRelatedLocations = async (
  client: ApolloClient<object>,
  cookie: string | undefined,
  formattedAddress: string,
  placeId: string,
) => {
  const {
    data: addrMatchLocsData,
  } =
    await client.query<
      V2Gateway_QueryLocationsQuery | null,
      V2Gateway_QueryLocationsQueryVariables
    >({
      query: V2Gateway_QueryLocationsDocument,
      context: cookie ? {
        headers: {
          cookie,
        }
      } : {},
      variables: {
        query: {
          types: [LocationType.Business, LocationType.Partner],
          search: formattedAddress,
        }
      },
    });

  const {
    data: placeIdMatchLocsData,
  } =
    await client.query<
      V2Gateway_QueryLocationsQuery | null,
      V2Gateway_QueryLocationsQueryVariables
    >({
      query: V2Gateway_QueryLocationsDocument,
      context: cookie ? {
        headers: {
          cookie,
        }
      } : {},
      variables: {
        query: {
          types: [LocationType.Business, LocationType.Partner],
          placeId,
        }
      },
    });

  const addrMatchLocs =
      addrMatchLocsData?.queryLocations.locations || [];
  const addrMatchLocUids = addrMatchLocs.map(al => al.uid);

  const placeIdMatchLocs =
      placeIdMatchLocsData?.queryLocations.locations.filter(
        l => !addrMatchLocUids.includes(l.uid)
      ) || [];
  const combined = [...addrMatchLocs, ...placeIdMatchLocs];

  return combined;
};

export default getRelatedLocations;

