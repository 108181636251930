import {
  LocationModFieldKey,
} from 'Common/components/LocationForm/functions';
import {
  CreateLocationInput,
  LocationType,
  UpdateLocationInput,
  V2Gateway_GetLocationsQueryHookResult,
  useV2Gateway_CreateLocationsMutation,
  useV2Gateway_UpdateLocationsMutation,
} from 'Generated/graphql';
import { Formik, FormikErrors, FormikProps, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import {
  LocationFormValues,
} from 'Common/components/LocationForm';
import {
  getLocationFormCreateFn,
  getLocationFormUpdateFn,
  getLocationFormFormikConfig,
} from './fn';
import { getSubmitFn } from 'Common/functions/Submit';
import { Box, Flex } from 'theme-ui';
import { getFormItems } from 'Common/components/LocationForm/functions';

export type LocationMutationReturn = {
  uid: string;
} | null

export type LocationFormMethods = {
  submit: () => Promise<LocationMutationReturn>,
  validate: () => (Promise<FormikErrors<LocationFormValues>> | undefined),
  reset: () => void,
};

type LocationFormProps = {
  existingLocation?: NonNullable<
    V2Gateway_GetLocationsQueryHookResult['data']
  >['getLocations'][number];
  hideFields?: LocationModFieldKey[];
  overrideFields?: Partial<LocationFormValues>;
  hideTypeOptions?: LocationType[];
  children?: React.ReactNode;
  onMethods: (
    methods: LocationFormMethods,
  ) => void;
}

const LocationFormElement: React.FC<Omit<LocationFormProps, 'onMethods'>> = ({
  hideFields: pHideFields = [],
  existingLocation,
  overrideFields,
  hideTypeOptions,
  children,
}) => {
  const {
    setFieldValue,
    getFieldProps,
  } = useFormikContext<LocationFormValues>();
  const formValueType = getFieldProps('type').value;
  const hideFields = formValueType === LocationType.Partner ?
    [...pHideFields, LocationModFieldKey.ExtendedAddress] :
    pHideFields;

  useEffect(() => {
    if (overrideFields) {
      Object.entries(overrideFields).forEach(([k, v]) => {
        setFieldValue(k, v);
      });
    }
  }, [overrideFields]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        gap: '1rem'
      }}
    >
      {getFormItems(
        hideFields,
        existingLocation,
        hideTypeOptions,
      )}
      <Box>
        {children}
      </Box>
    </Flex>
  );
};

const LocationForm: React.FC<LocationFormProps> = ({
  existingLocation,
  hideFields = [],
  overrideFields,
  hideTypeOptions,
  children,
  onMethods,
}) => {
  const formikRef = useRef<
    FormikProps<Partial<LocationFormValues>>
  >(null);

  const [
    createLocations,
  ] = useV2Gateway_CreateLocationsMutation();
  const [
    updateLocations,
  ] = useV2Gateway_UpdateLocationsMutation();

  type cf = ((d: CreateLocationInput) => Promise<LocationMutationReturn>)
  type uf = ((d: UpdateLocationInput) => Promise<LocationMutationReturn>)

  const submit = getSubmitFn<
  LocationMutationReturn,
  CreateLocationInput,
  UpdateLocationInput,
  cf,
  uf
  >(
    getLocationFormCreateFn(createLocations),
    getLocationFormUpdateFn(updateLocations),
  );

  const submitForm = async () => {
    if (formikRef.current) {
      const data = await submit(
        formikRef.current.values as LocationFormValues,
      );
      return data;
    }
    return Promise.reject('empty formikRef');
  };

  const validateForm = () => {
    return formikRef.current?.validateForm();
  };

  const resetForm = () => {
    formikRef.current?.resetForm();
  };

  useEffect(() => {
    onMethods({
      submit: submitForm,
      validate: validateForm,
      reset: resetForm,
    });
  }, []);

  return (
    <>
      <Formik
        innerRef={formikRef}
        {...getLocationFormFormikConfig(
          existingLocation || null,
          submit,
        )}
      >
        <LocationFormElement
          hideFields={hideFields}
          existingLocation={existingLocation}
          overrideFields={overrideFields}
          hideTypeOptions={hideTypeOptions}
        >
          {children}
        </LocationFormElement>
      </Formik>
    </>
  );
};

export default LocationForm;
