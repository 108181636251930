import React, { useState } from 'react';
import {
  default as usePlacesService,
} from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { List } from 'antd';
import useScript from 'Common/hooks/useScript';
import { SearchProps } from 'antd/lib/input';
import { Input, Spinner, Box } from 'theme-ui';
import { AutocompletePrediction } from 'react-places-autocomplete';

type GooglePlacesAutocompleteProps = {
  defaultAddress?: string;
  onSelect: (
    place: AutocompletePrediction | undefined
  ) => void;
  onBlur?: () => void;
  // Omit these props because of weird type error
  inputProps?: Omit<SearchProps, 'sx' | 'css' | 'size' | 'prefix'>;
  ['data-testid']?: string;
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  defaultAddress,
  onSelect,
  onBlur,
  inputProps,
  ...props
}) => {
  const [place, setPlace] = useState<
    AutocompletePrediction | null
  >(null);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    debounce: 500,
  });
  const testId = props['data-testid'];

  return (
    <Box sx={{
      position: 'relative',
      width: '100%'
    }}>
      <Input
        data-testid={testId}
        style={{
          color: 'black',
        }}
        defaultValue={defaultAddress}
        {...(place ? {
          value: place.description,
        } : {})}
        onChange={(evt) => {
          getPlacePredictions({
            input: evt.target.value,
            componentRestrictions: {
              country: 'CA',
            },
            types: ['premise', 'street_address']
          });
        }}
        placeholder='Address'
        onKeyDown={() => {
          if (place) {
            setPlace(null);
          }
        }}
        onBlur={() => {
          if (typeof onBlur !== 'undefined') {
            onBlur();
          } /*else if (!place && placePredictions.length) {
            setPlace(placePredictions[0]);
            onSelect(placePredictions[0]);
          }*/
        }}
        {...inputProps}
      />
      {!place && !isPlacePredictionsLoading &&
        Boolean(placePredictions.length) && (
        <List
          dataSource={placePredictions}
          renderItem={(item, ix) => (
            <List.Item
              data-testid={`${testId}_result_${ix}`}
              onClick={() => {
                setPlace(item);
                onSelect(item);
              }}
              sx={{
                cursor: 'pointer',
                maxWidth: ['210px', '', 'fit-content'],
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                }
              }}
            >
              {item.description}
            </List.Item>
          )}
          locale={{
            emptyText: <></>,
          }}
        />
      )}
      {isPlacePredictionsLoading && (
        <Spinner
          size={32}
          sx={{
            position: 'absolute',
            right: '2rem',
            top: '0',
          }}
        />
      )}
    </Box>
  );
};

const GooglePlacesAutocompleteWithScript: React.FC<
  GooglePlacesAutocompleteProps
> = (props) => {
  const loaded = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY
    }&libraries=places&t=_`
  );

  return loaded ? (
    <GooglePlacesAutocomplete {...props} />
  ) : <Input disabled />;
};

export default GooglePlacesAutocompleteWithScript;
