import { LocationPolicyType, LocationPolicyValueType } from 'Generated/graphql';
import * as yup from 'yup';

const commonLocationPolicyFormValidationSchema = {
  value: yup.string().required(),
  name: yup.string().required(),
};

const valueTypeFragment = Object.values(LocationPolicyValueType).join(')|(');
const valTypeRegEx = new RegExp(
  `(${valueTypeFragment})`
);

const policyTypeFragment = Object.values(LocationPolicyType).join(')|(');
const polTypeRegEx = new RegExp(
  `(${policyTypeFragment})`
);

export const createLocationPolicyFormValidationSchema = yup.object({
  externalUid: yup.string().required(),
  valueType: yup.string().matches(valTypeRegEx).required(),
  policyType: yup.string().matches(polTypeRegEx).required(),
}).shape(commonLocationPolicyFormValidationSchema);

export const updateLocationPolicyFormValidationSchema = yup.object({
  uid: yup.string().required(),
}).shape(commonLocationPolicyFormValidationSchema);
