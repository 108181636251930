import React from 'react';
import {
  V2Gateway_QueryLocationsLazyQueryHookResult,
} from 'Generated/graphql';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import ListItem from 'Common/components/generic/ListItem';
import { UseLocationPolicies } from 'Common/hooks/useLocationPolicies';

type Locations = NonNullable<
  V2Gateway_QueryLocationsLazyQueryHookResult[1]['data']
>['queryLocations']['locations']

type Location = Locations[number]

export type LocationNameAddress = Pick<
  Location, 'formattedAddress' | 'name' | 'uid'
>

export type LocationWithLatLng = Pick<
  Location, 'formattedAddress' | 'name' | 'uid' | 'latitude' | 'longitude'
>

export type LocationSelectListItemProps<
  T extends LocationWithLatLng = LocationWithLatLng
> = {
  extra?: (
    l: T,
    pol?: UseLocationPolicies,
  ) => React.ReactNode | React.ReactNode[];
  location: T;
  onClick?: (l: T) => void;
  styles?: ThemeUIStyleObject;
  policies?: UseLocationPolicies;
  canJoin?: boolean
  children?: React.ReactNode;
  testId?: string;
}

const LocationSelectListItem: React.FC<LocationSelectListItemProps> = ({
  extra,
  location: l,
  onClick,
  styles,
  policies,
  children,
  testId,
}) => {

  return (
    <>
      <Flex
        data-testid={testId}
        sx={{
          ...styles,
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: onClick === undefined ? 'unset' : 'pointer',
          borderRadius: '10px',
          p: '1rem 0',
          ':hover': onClick === undefined ? {} : {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => onClick && onClick(l)}
      >
        <Box
          sx={{
            flexBasis: ['75%', 'unset'],
          }}
        >
          <ListItem
            title={l.name}
            description={l.formattedAddress}
          />
        </Box>
        {extra && (
          <Box>
            {extra(l, policies)}
          </Box>
        )}
        {children}
      </Flex>
    </>
  );
};

export default LocationSelectListItem;
