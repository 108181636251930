import {
  Formik,
  Field,
  Form,
  FormikErrors,
  FormikProps,
  useFormikContext,
} from 'formik';
import { Input, Select } from 'antd';
import {
  CreateServiceAreaInput,
  ServiceArea,
  ServiceAreaSize,
  UpdateServiceAreaInput,
  V2Gateway_GetServiceAreasQueryHookResult,
  useV2Gateway_CreateServiceAreasMutation,
  useV2Gateway_UpdateServiceAreasMutation
} from 'Generated/graphql';
import React, { useEffect, useRef } from 'react';
import {
  getServiceAreaFormCreateFn,
  getServiceAreaFormFormikConfig,
  getServiceAreaFormUpdateFn,
} from './fn';
import { getSubmitFn } from 'Common/functions/Submit';
import { Label, Text } from 'theme-ui';

export type ServiceAreaFormValues =
  CreateServiceAreaInput | UpdateServiceAreaInput

export type ServiceAreaMutationReturn = {
  uid: string;
} | null

export type ServiceAreaFormMethods = {
  submit: (extenalUid: string) => Promise<ServiceAreaMutationReturn>,
  validate: () => (Promise<FormikErrors<ServiceAreaFormValues>> | undefined),
  reset: () => void,
};

enum ModFieldKey {
  ExternalUid = '0',
  Uid = '1',
  Name = '2',
  Size = '3',
}

type ServiceAreaFormProps = {
  existingServiceArea?: NonNullable<
    V2Gateway_GetServiceAreasQueryHookResult['data']
  >['getServiceAreas'][number];
  hideFields?: ModFieldKey[];
  onMethods: (
    methods: ServiceAreaFormMethods,
  ) => void;
}

const ModFields: Record<ModFieldKey, keyof ServiceArea> = {
  [ModFieldKey.ExternalUid]: 'externalUid',
  [ModFieldKey.Uid]: 'uid',
  [ModFieldKey.Name]: 'name',
  [ModFieldKey.Size]: 'size',
};

const descriptionForSize: Record<ServiceAreaSize, string> = {
  [ServiceAreaSize.Small]: 'Less then 10 people',
  [ServiceAreaSize.Medium]: 'Up to 25 people',
  [ServiceAreaSize.Large]: 'Up to 100 people',
  [ServiceAreaSize.ExtraLarge]: 'Greater then 100 people',
};

const ServiceAreaForm: React.FC<ServiceAreaFormProps> = ({
  existingServiceArea: existingMod,
  hideFields = [],
}) => {
  const { setFieldValue } = useFormikContext<ServiceAreaFormValues>();

  const allItems = Object.entries(ModFields).map(([k, ff]) => {
    const ffk = k as unknown as ModFieldKey;
    if (!hideFields.includes(ffk)) {
      switch (ffk) {
      case ModFieldKey.Uid:
        return existingMod ? <Field name={ff} type="hidden" /> : null;
      case ModFieldKey.ExternalUid:
        return <Field name={ff} type="hidden" />;
      case ModFieldKey.Name:
        return (
          <Label>
              Name
            <Field as={Input} name={ff} />
          </Label>
        );
      case ModFieldKey.Size:
        return (
          <Label>
              Size
            <Field
              as={Select}
              name={ff}
              onChange={(e: string) => {
                setFieldValue(ff, e);
              }}
            >
              {Object.entries(ServiceAreaSize).map(([k, v]) => (
                <Select.Option key={v} value={v}>
                  {k} - {descriptionForSize[v]}
                </Select.Option>
              ))}
            </Field>
            <Text>
                The estimated capacity of this area
            </Text>
          </Label>
        );
      }
    }
  });

  return <Form>{allItems}</Form>;
};

const ServiceAreaFormWithFormik: React.FC<ServiceAreaFormProps> = (props) => {
  const formikRef = useRef<
    FormikProps<Partial<ServiceAreaFormValues>>
  >(null); // This is a ref that will be attached to Formik

  const [
    createServiceAreas,
  ] = useV2Gateway_CreateServiceAreasMutation();
  const [
    updateServiceAreas,
  ] = useV2Gateway_UpdateServiceAreasMutation();

  type cf = ((d: CreateServiceAreaInput) => Promise<ServiceAreaMutationReturn>)
  type uf = ((d: UpdateServiceAreaInput) => Promise<ServiceAreaMutationReturn>)

  const submit = getSubmitFn<
    ServiceAreaMutationReturn,
    CreateServiceAreaInput,
    UpdateServiceAreaInput,
    cf,
    uf
  >(
    getServiceAreaFormCreateFn(createServiceAreas),
    getServiceAreaFormUpdateFn(updateServiceAreas),
  );

  const submitForm = async (externalUid: string) => {
    if (formikRef.current) {
      const data = await submit(
        {
          ...formikRef.current.values as ServiceAreaFormValues,
          externalUid,
        },
      );
      return data;
    }
    return Promise.reject('empty formikRef');
  };

  const validateForm = () => {
    return formikRef.current?.validateForm();
  };

  const resetForm = () => {
    formikRef.current?.resetForm();
  };

  useEffect(() => {
    props.onMethods({
      submit: submitForm,
      validate: validateForm,
      reset: resetForm,
    });
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      {...getServiceAreaFormFormikConfig(
        props.existingServiceArea || null,
        submit,
      )}
    >
      <ServiceAreaForm {...props} />
    </Formik>
  );
};

export default ServiceAreaFormWithFormik;

