import { Field, FieldProps } from 'formik';
import { CreateLocationInput } from 'Generated/graphql';
import { Text, Label, ThemeUIStyleObject } from 'theme-ui';
import theme from 'Common/theme';
import PhoneNumberInput from 'Common/components/PhoneNumberInput';
import { E164Number } from 'libphonenumber-js/min';

type PhoneNumberFieldProps = {
  name: string;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  name,
}) => {
  const fieldFn =
    ({
      form,
      field,
      meta,
    }: FieldProps<CreateLocationInput['phoneNumber']>) => {
      return (
        <>
          <Label
            key={name}
            sx={{
              '& .PhoneInputInput': (meta.error ?
                theme.forms?.inputError :
                {
                  ...theme.forms?.input,
                  p: '8px',
                }) as ThemeUIStyleObject,
              // Remove once we are targetting USA users again
              '& .PhoneInputCountry': {
                display: 'none',
              },
            }}
          >
            <PhoneNumberInput
              numberInputProps={{
                ['data-testid']: 'locationForm_phoneNumberInput',
              }}
              defaultCountry="CA"
              onChange={v => {
                form.setFieldValue(name, v);
              }}
              value={field.value ? (field.value as E164Number) : undefined}
              placeholder="Phone Number"
            />
          </Label>
          {meta.error && (
            <Text as="small" variant="text.error">
              {meta.error}
            </Text>
          )}
        </>
      );
    };

  return (
    <>
      <Field label="" name={name}>
        {fieldFn}
      </Field>
    </>
  );
};

export default PhoneNumberField;


