import React from 'react';
import { Flex } from 'theme-ui';
import ListItem from 'Common/components/generic/ListItem';
import { ManageLocationPoliciesLocationPolicy } from '../..';
import {
  getLocationPolicyListItemEditButton,
} from './functions';

export type LocationPolicyListItemProps = {
  existingMod: ManageLocationPoliciesLocationPolicy;
  onClickEdit?: (
    appointmentRequest?: ManageLocationPoliciesLocationPolicy
  ) => void;
  hideEditButton?: boolean;
  disabled?: boolean;
}

const LocationPolicyListItem: React.FC<LocationPolicyListItemProps> = ({
  existingMod,
  onClickEdit,
  hideEditButton,
  disabled,
}) => {
  return existingMod ? (
    <>
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Flex
          sx={{
            flexBasis: '100%',
            justifyContent: 'space-between',
            p: '1rem 0',
            alignItems: 'center',
          }}
        >
          <ListItem
            title={existingMod.name}
            description={
              `${existingMod.policyType
                .replace(/_/g, ' ')
                .toLowerCase()} - ${existingMod.value}`
            }
          />
          <Flex
            sx={{
              flexBasis: '10%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hideEditButton || getLocationPolicyListItemEditButton(
              () => onClickEdit && onClickEdit(existingMod),
            )(Boolean(disabled))}
          </Flex>
        </Flex>
      </Flex>
    </>
  ) : null;
};

export default LocationPolicyListItem;
